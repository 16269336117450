import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSearchBar, searchSpotify } from 'redux/actions/searchBarActions';

const useSimilarityInput = () => {
  const { spotifyResults, loading } = useSelector((state) => state.searchBar);
  const [term, setTerm] = useState('');
  const debouncedValue = useDebounce(term, 500);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!debouncedValue?.length) return;
    dispatch(searchSpotify({ term: debouncedValue }));
  }, [dispatch, debouncedValue]);

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleClose = () => {
    setTerm('');
    dispatch(clearSearchBar());
  };

  const handleSubmit = (result) => {
    const params = new URLSearchParams();
    params.set('ssp', result.id);
    params.set('t', result.title);
    params.set('a', result.artist);

    handleClose();

    history.push(`/search?${params.toString()}`);
  };

  return {
    results: spotifyResults,
    loading,
    term,
    debouncedValue,
    handleChange,
    handleSubmit,
    handleClose,
  };
};

export default useSimilarityInput;
