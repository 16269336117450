import React from 'react';
import PaymentComponent from 'common/Payment/PaymentComponent';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/actions/modalActions';
import Form from 'common/Form';
import { StyledSubscribeModal } from './SubscribeModal.styled';

const SubscribeModal = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(openModal('channel'));
  };

  return (
    <StyledSubscribeModal>
      <div className="modal-header">
        <h2>Subscribe</h2>
        <PaymentComponent handleNext={handleNext} />
        <Form>
          <p className="alternate">Cancel anytime</p>
          <p className="alternate">or</p>
          <p className="alternate">
            <span className="link" onClick={handleClose}>
              Skip this step
            </span>{' '}
            and do it later.
          </p>
        </Form>
      </div>
    </StyledSubscribeModal>
  );
};

export default SubscribeModal;
