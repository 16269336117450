import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearSearchBar } from 'redux/actions/searchBarActions';

const useSearchBar = () => {
  const { loading } = useSelector((state) => state.searchBar);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const [selected, setSelected] = useState(
    location.pathname === '/aisearch' ? 'ai' : 'keyword'
  );
  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleSelect = (val) => {
    setSelected(val);
    dispatch(clearSearchBar());

    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  };

  return {
    loading,
    inputRef,
    selected,
    user,
    handleSelect,
  };
};

export default useSearchBar;
