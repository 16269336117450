const initialState = {
  tags: [],
  included: [],
  excluded: [],
  keywords: null,
  similarLibrary: null,
  similarSpotify: null,
  similarTitle: null,
  similarArtist: null,
  loading: false,
  keywordsLookup: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TAGS_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'GET_TAGS_SUCCESS':
      return {
        ...state,
        tags: action.payload,
        loading: false,
        error: null,
      };

    case 'SET_TAGS':
      const { inc, exc, slb, ssp, t, a, keywords } = action.payload;
      const temp = state.keywordsLookup;

      const included = inc.map((t) => {
        const tag = state.tags.find((st) => st.id === t);
        return { ...tag, custom: state.keywordsLookup?.[t] };
      });

      if (state.keywordsLookup) {
        Object.keys(state.keywordsLookup).forEach((key) => {
          if (!inc.includes(+key)) {
            delete temp[key];
          }
        });
      }

      return {
        ...state,
        included: included,
        excluded: state.tags.filter((tag) => exc?.includes(tag.id)),
        similarLibrary: slb,
        similarSpotify: ssp,
        similarTitle: t,
        similarArtist: a,
        keywords,
        keywordsLookup: temp,
        loading: false,
        error: null,
      };

    case 'CONVERT_KEYWORDS_SUCCESS':
      const tags = state.included.length
        ? state.included
        : Object.entries(action.payload).map(([id, custom]) => {
            const tag = state.tags.find((t) => t.id === +id);
            return { ...tag, custom };
          });

      return {
        ...state,
        included: tags,
        keywords: null,
        keywordsLookup: action.payload,
      };

    case 'GET_TAGS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_TAGS':
      return {
        ...state,
        included: initialState.included,
        excluded: initialState.excluded,
      };

    default:
      return state;
  }
};

export default reducer;
