import React from 'react';
import AddToProjectForm from './AddToProjectForm';

const AddToProject = ({
  projects,
  track,
  loading,
  handleSwitch,
  handleAddToProject,
}) => {
  const activeProjects = projects.filter((item) => {
    return item.tracks.some((t) => t.id === track.id);
  });
  const options = projects.reduce((arr, project) => {
    if (project.tracks.some((t) => t.id === track.id)) return arr;
    return [...arr, { id: project.id, value: project.name }];
  }, []);

  return (
    <>
      <div className="modal-text-header">
        <h2>Add to Project</h2>

        {!!activeProjects.length ? (
          <div style={{ margin: '36px 0' }}>
            <p>
              <span className="highlight">{track.title}</span>
              {options.length
                ? ` is currently in:`
                : ` is currently in all of your projects:`}
            </p>
            {activeProjects.map((item, i) => (
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 'smaller',
                  color: '#fff',
                  marginBottom: '4px',
                }}
                key={i}
              >
                {item.name}
              </p>
            ))}
          </div>
        ) : (
          <p>
            Add <span className="highlight">{track.title}</span> to an existing
            project
          </p>
        )}
      </div>
      <AddToProjectForm
        loading={loading}
        projects={options}
        handleSwitch={handleSwitch}
        onSubmit={handleAddToProject}
      />
    </>
  );
};

export default AddToProject;
