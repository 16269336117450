import React from 'react';
import { StyledSubDetails } from './SubscriptionDetails.styled';
import { SUBSCRIPTION_TYPES } from 'constants/subscriptions';
import { SubscriptionSelectField } from 'common/Form';

const SubscriptionDetails = ({ account, formik, coupon }) => {
  const couponValue = () => {
    if (coupon?.amount_off) {
      return `$${coupon?.amount_off}`;
    }
    if (coupon?.percent_off) {
      return `${coupon?.percent_off}%`;
    }
    return false;
  };

  const applyDiscount = (value, trim) => {
    let finalValue = value;

    if (coupon?.amount_off) {
      finalValue = value - coupon.amount_off;
    }
    if (coupon?.percent_off) {
      finalValue = value - value * (coupon.percent_off / 100);
    }
    return finalValue % 1 === 0 && trim ? finalValue : finalValue.toFixed(2);
  };

  const subTypes = SUBSCRIPTION_TYPES.filter((type) => !type.disabled);

  const plan = coupon?.plan_type;

  const selectedIndex = plan
    ? subTypes.findIndex((item) => item.type === plan)
    : formik.values.plan;

  const currentPlan = subTypes[selectedIndex];

  return (
    <>
      <SubscriptionSelectField
        account={account}
        subTypes={subTypes}
        coupon={coupon}
        formik={formik}
        selectedIndex={selectedIndex}
      />
      <StyledSubDetails>
        {coupon && (
          <>
            <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
              "<strong>{coupon.name}</strong>" coupon applied for{' '}
              <strong>{couponValue()}</strong> off
              {plan && ` the ${currentPlan.name} plan`}!
            </p>
            <hr />
            <div className="pricing-row">
              <p>Subtotal</p>
              <p>{currentPlan.year.toFixed(2)}</p>
            </div>
            <div className="pricing-row">
              <p>Discount</p>
              <p className="green">
                {(applyDiscount(currentPlan.year) - currentPlan.year).toFixed(
                  2
                )}
              </p>
            </div>
          </>
        )}
        <hr />
        <div className="pricing-row total">
          <p>Order Total</p>
          <p>${applyDiscount(currentPlan.year)}</p>
        </div>
      </StyledSubDetails>
    </>
  );
};

export default SubscriptionDetails;
