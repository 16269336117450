import axios from './config/axiosConfig';

export const createSubscription = async ({ name, code }) => {
  try {
    const res = await axios.post(`/api/accounts/users/subscription`, {
      plan_type: name,
      promo_code: code,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updatePaymentMethod = async (payload) => {
  try {
    const res = await axios.put(`/api/accounts/users/subscription`, payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const toggleRenewal = async () => {
  try {
    const res = await axios.put(`/api/accounts/users/subscription`, {
      auto_renew: null,
    });
    return res.data.user;
  } catch (error) {
    throw error;
  }
};

export const checkPromoCode = async (code) => {
  try {
    const res = await axios.post(`/api/accounts/stripe/check-code`, {
      promo_code: code,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
