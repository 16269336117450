import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { downloadTrack } from 'redux/actions/downloadsActions';
import { addFavorite, removeFavorite } from 'redux/actions/favoritesActions';
import { openModal } from 'redux/actions/modalActions';
import { setProjectTrack } from 'redux/actions/projectsActions';

const useTrack = (track) => {
  const { favorites } = useSelector((state) => state.favorites);
  const { downloading } = useSelector((state) => state.downloads);
  const { purchases } = useSelector((state) => state.purchases);
  const favorite = favorites.includes(track?.id);
  const dispatch = useDispatch();
  const history = useHistory();

  const paid = purchases.some((p) => p.id === track.id);

  const handleFavorite = (e) => {
    e.stopPropagation();
    if (favorite) {
      return dispatch(removeFavorite(track.id));
    }
    dispatch(addFavorite(track.id));
  };

  const handleProject = (e) => {
    e.stopPropagation();
    dispatch(setProjectTrack(track));
  };

  const handleSimilar = (e) => {
    e.stopPropagation();

    const params = new URLSearchParams();
    params.set('slb', track.id);
    params.set('t', track.title);
    params.set('a', track.primary_artists[0].name);

    history.push(`/search?${params.toString()}`);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    dispatch(downloadTrack(track));
  };

  const handleStems = (e) => {
    e.stopPropagation();
    dispatch(openModal('stems', { track }));
  };

  return {
    paid,
    favorite,
    downloading: downloading === track.id,
    handleFavorite,
    handleProject,
    handleSimilar,
    handleDownload,
    handleStems,
  };
};

export default useTrack;
