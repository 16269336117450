import React from 'react';
import { StyledModalClose } from './Modal.styled';
import { ReactComponent as Plus } from 'images/icons/plus.svg';

const ModalClose = ({ color, handleClick }) => {
  return (
    <StyledModalClose color={color} onClick={handleClick}>
      <Plus />
    </StyledModalClose>
  );
};

export default ModalClose;
