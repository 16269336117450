import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'images/icons/chevron-left.svg';
import { StyledBackButton } from '../Button.styled';

const PageBackButton = ({ path }) => {
  const history = useHistory();

  const handleClick = () => {
    if (path) {
      history.push(path);
      return;
    }
    history.goBack();
  };
  return (
    <StyledBackButton>
      <button onClick={handleClick}>
        <ChevronLeft /> Back
      </button>
    </StyledBackButton>
  );
};

export default PageBackButton;
