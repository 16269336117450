import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { userSignOut } from 'redux/actions/userActions';
import { openModal } from 'redux/actions/modalActions';
import { StyledHeaderAuth } from './HeaderAuth.styled';
import UserMenu from 'common/Layout/Header/Auth/Menu/MenuLoggedIn';
import { Button } from 'common/Button';
import MenuLoggedOut from './Menu/MenuLoggedOut';

const HeaderAuth = ({ tablet }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignUp = () => dispatch(openModal('signup'));
  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(openModal('signin'));
  };
  const handleSignOut = () => {
    history.push('/');
    dispatch(userSignOut());
  };

  return (
    <StyledHeaderAuth>
      {user ? (
        <li>
          <UserMenu user={user} tablet={tablet} handleSignOut={handleSignOut} />
        </li>
      ) : !tablet ? (
        <>
          <li>
            <NavLink to="/pricing">Pricing</NavLink>
          </li>
          <li>
            <NavLink to="/faq">FAQ</NavLink>
          </li>
          <li>
            <button className="link" onClick={handleSignIn}>
              Sign In
            </button>
          </li>
          <li>
            <Button primary onClick={handleSignUp}>
              Sign Up
            </Button>
          </li>
        </>
      ) : (
        <li>
          <MenuLoggedOut
            handleSignIn={handleSignIn}
            handleSignUp={handleSignUp}
          />
        </li>
      )}
    </StyledHeaderAuth>
  );
};

export default HeaderAuth;
