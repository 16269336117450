import React, { useEffect } from 'react';
import Form, { InputField, SubmitButton } from 'common/Form';
import useForgotForm from './useForgotForm';

const ForgotForm = ({ loading, error, handleSubmit, handleForgot }) => {
  const formik = useForgotForm(handleSubmit);
  const { setFieldError } = formik;

  useEffect(() => {
    if (error) {
      error.forEach((err) => {
        setFieldError(err[0], err[1]);
      });
    }
  }, [error, setFieldError]);

  return (
    <Form handleSubmit={formik.handleSubmit}>
      <InputField label="Email" name="email" type="email" formik={formik} />
      <SubmitButton loading={loading}>Continue</SubmitButton>
      <p className="alternate">
        <span className="link" onClick={handleForgot}>
          Cancel
        </span>
      </p>
    </Form>
  );
};

export default ForgotForm;
