import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addChannel } from 'redux/actions/channelActions';

const initialValues = {
  channel_id: '',
  channel_name: '',
};

const validate = (values) => {
  const errors = {};

  if (!values.channel_id) {
    errors.channel_id = 'Required';
  } else if (values.channel_id.length !== 24) {
    errors.channel_id = 'The YouTube channel ID must be 24 characters';
  }
  if (!values.channel_name) {
    errors.channel_name = 'Required';
  }
  return errors;
};

const useChannelForm = (handleClose) => {
  const { user } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.channel);
  const dispatch = useDispatch();

  const multipleArray = [
    'Tester',
    'Creator',
    'Broadcast',
    'Enterprise',
    'personamusic_tier2',
  ];
  const multiple = multipleArray.includes(user.subscription?.type);

  const handleSubmit = (values, { resetForm }) => {
    const message = `Once this channel is added, it can not be removed.  Are you sure?`;

    if (multiple) {
      return dispatch(addChannel(values)).then((err) => {
        if (!err) resetForm();
        handleClose?.();
      });
    }
    if (window.confirm(message)) {
      dispatch(addChannel(values)).then((err) => {
        if (!err) resetForm();
        handleClose?.();
      });
    }
  };
  const formik = useFormik({ initialValues, validate, onSubmit: handleSubmit });
  const { setFieldError } = formik;

  useEffect(() => {
    if (error) {
      error.forEach((err) => {
        setFieldError(err[0], err[1]);
      });
    }
  }, [error, setFieldError]);

  return { formik, loading, multiple };
};

export default useChannelForm;
