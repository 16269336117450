import styled from 'styled-components';

export const StyledWaveform = styled.div`
  position: relative;
  width: 100%;

  .progress {
    position: relative;
    transition: clip-path 0.2s linear;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  svg {
    display: block;
  }
`;
