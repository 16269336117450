import SearchResultsLoading from 'common/Layout/Header/Search/Results/SearchResultsLoading';
import {
  StyledSearchResultItem,
  StyledSearchResults,
} from 'common/Layout/Header/Search/SearchBar.styled';
import React from 'react';

const SpotifySearchResults = (props) => {
  const { results, loading, handleClick, handleClose } = props;

  return (
    <StyledSearchResults>
      <div className="content-wrapper">
        <div className="content">
          {loading && !results ? (
            <SearchResultsLoading />
          ) : (
            results.map((result) => (
              <StyledSearchResultItem key={result.id} image={result.image}>
                <div
                  className="thumbnail"
                  onClick={() => handleClick(result.id)}
                ></div>
                <div className="details" onClick={() => handleClick(result)}>
                  <div className="title">{result.title}</div>
                  <div className="text">{result.artist}</div>
                </div>
              </StyledSearchResultItem>
            ))
          )}
        </div>
      </div>
      <div className="underlay-arriba" onClick={handleClose}></div>
    </StyledSearchResults>
  );
};

export default SpotifySearchResults;
