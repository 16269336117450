import React from 'react';
import StripeWrapper from 'common/Stripe/StripeWrapper';
import CheckoutForm from './Checkout/CheckoutForm';
import { StyledPaymentComponent } from './PaymentComponent.styled';
import { useSelector } from 'react-redux';

const PaymentComponent = ({ handleNext, account }) => {
  const { user } = useSelector((state) => state.auth);
  const { subscription: sub } = user;

  return sub?.type !== 'Tester' ? (
    <StyledPaymentComponent className="thin">
      {sub?.type && sub?.paid_expiration && <h3>Update Payment Method</h3>}
      <StripeWrapper>
        <CheckoutForm account={account} handleNext={handleNext} />
      </StripeWrapper>
    </StyledPaymentComponent>
  ) : null;
};

export default PaymentComponent;
