import React from 'react';
import { StyledForm } from './Form.styled';

const Form = ({ handleSubmit, children, ...props }) => (
  <StyledForm onSubmit={handleSubmit} {...props}>
    {children}
  </StyledForm>
);

export default Form;
