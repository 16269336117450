import React from 'react';
import { FormGroup, Error, CheckboxContainer } from '../Form.styled';

const CheckboxFields = ({ fields, formik }) => (
  <FormGroup>
    {fields.map((field) => (
      <CheckboxContainer className="checkbox-container" key={field.name}>
        <label key={field.name}>
          {field.label}
          <input
            type="checkbox"
            id={field.name}
            name={field.name}
            checked={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <span className="checkmark"></span>
        </label>
        {formik.errors[field.name] && formik.touched[field.name] && (
          <Error>{formik.errors[field.name]}</Error>
        )}
      </CheckboxContainer>
    ))}
  </FormGroup>
);

export default CheckboxFields;
