import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RouteChange from './RouteChange';

const LandingPage = lazy(() => import('features/LandingPage/LandingPage'));
const HomePage = lazy(() => import('features/HomePage/HomePage'));
const MusicPage = lazy(() => import('features/MusicPage/MusicPage'));
const PlaylistsPage = lazy(() =>
  import('features/PlaylistsPage/PlaylistsPage')
);
const AlbumPage = lazy(() => import('features/AlbumPage/AlbumPage'));
const ArtistPage = lazy(() => import('features/ArtistPage/ArtistPage'));
const PlaylistPage = lazy(() => import('features/PlaylistPage/PlaylistPage'));
const PartnerPage = lazy(() => import('features/PartnerPage/PartnerPage'));
const PricingPage = lazy(() => import('features/PricingPage/PricingPage'));
const FaqPage = lazy(() => import('features/FaqPage/FaqPage'));
const UserPage = lazy(() => import('features/UserPage/UserPage'));
const SearchPage = lazy(() => import('features/SearchPage/SearchPage'));
const AIChatPage = lazy(() => import('features/AIChatPage/AISearchPage'));

const SuccessPage = lazy(() =>
  import('features/SignUpModal/SuccessPage/SuccessPage')
);
const VerifyPage = lazy(() =>
  import('features/SignUpModal/VerifyPage/VerifyPage')
);
const ResetPage = lazy(() => import('features/ResetPage/ResetPage'));
const ActivatePage = lazy(() => import('features/ActivatePage/ActivatePage'));
const Four04 = lazy(() => import('./Four04/Four04'));

const Routes = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const requireAuth = (next) => {
    // TODO: include different auth types for VOD or admin
    if (!user) {
      // TODO: Toast is firing on sign-out. Lame. Revisit.
      // toastr.error(`Please sign in to view that page`);
      return <Redirect to={`/?redirect=${location.pathname}`} />;
    }
    return next;
  };

  return (
    <Suspense fallback={<div></div>}>
      <RouteChange>
        <Switch>
          <Route exact path="/" component={user ? HomePage : LandingPage} />
          <Route path="/music" component={MusicPage} />
          <Route path="/playlists" component={PlaylistsPage} />
          <Route exact path="/playlist/:id" component={PlaylistPage} />
          <Route exact path="/artist/:id" component={ArtistPage} />
          <Route exact path="/creator/:id" component={PartnerPage} />
          <Route exact path="/album/:id" component={AlbumPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/search" component={SearchPage} />
          <Route
            exact
            path="/aisearch"
            render={() => requireAuth(<AIChatPage />)}
          />
          <Route exact path="/success" component={SuccessPage} />
          <Route exact path="/verify" component={VerifyPage} />
          <Route exact path="/reset-password" component={ResetPage} />
          <Route exact path="/activate" component={ActivatePage} />
          <Route path="/user" render={() => requireAuth(<UserPage />)} />
          <Route component={Four04} />
        </Switch>
      </RouteChange>
    </Suspense>
  );
};

export default Routes;
