import React from 'react';
import { FormGroup } from '../Form.styled';
import { LargeSwitch } from 'common/Button';
import PlanPrice from 'features/PricingPage/Options/PlanPrice/PlanPrice';

const SubscriptionSelectField = ({
  formik,
  account,
  coupon,
  subTypes,
  selectedIndex,
}) => {
  const applyDiscount = (value, trim) => {
    let finalValue = value;

    if (coupon?.amount_off) {
      finalValue = value - coupon.amount_off;
    }
    if (coupon?.percent_off) {
      finalValue = value - value * (coupon.percent_off / 100);
    }
    return finalValue % 1 === 0 && trim ? finalValue : finalValue.toFixed(2);
  };

  return (
    <>
      <FormGroup>
        <LargeSwitch
          account={account}
          selectedIndex={selectedIndex}
          items={subTypes.map((type) => type.name)}
          handleChange={(i) => formik.setFieldValue('plan', i)}
        />
      </FormGroup>
      <div className="subscription-prices">
        {subTypes.map((subType, i) => (
          <div
            key={i}
            className={`subscription-type${
              selectedIndex === i ? ' active' : ''
            }`}
          >
            <PlanPrice {...subType} month={applyDiscount(subType.month)} />
          </div>
        ))}
      </div>
    </>
  );
};

export default SubscriptionSelectField;
