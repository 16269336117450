import React from 'react';
import { Link } from 'react-router-dom';

const SearchButton = ({ name, path, selected, children, handleClick }) => (
  <Link
    to={path}
    className={selected === name ? 'active' : undefined}
    onClick={() => handleClick(name)}
  >
    {children}
  </Link>
);

export default SearchButton;
