import * as purchaseApi from 'api/purchasesApi';

const types = {
  GET_PURCHASES_LOADING: 'GET_PURCHASES_LOADING',
  GET_PURCHASES_SUCCESS: 'GET_PURCHASES_SUCCESS',
  GET_PURCHASES_ERROR: 'GET_PURCHASES_ERROR',
  PURCHASE_TRACK_LOADING: 'PURCHASE_TRACK_LOADING',
  PURCHASE_TRACK_SUCCESS: 'PURCHASE_TRACK_SUCCESS',
  PURCHASE_TRACK_ERROR: 'PURCHASE_TRACK_ERROR',
  SET_ACTIVE_TRACK: 'SET_ACTIVE_TRACK',
  CLEAR_ACTIVE_TRACK: 'CLEAR_ACTIVE_TRACK',
  UPDATE_PAYMENT_INTENT: 'UPDATE_PAYMENT_INTENT',
};

export const getPurchases = () => (dispatch) => {
  dispatch({ type: types.GET_PURCHASES_LOADING });
  return purchaseApi
    .getPurchases()
    .then((purchases) => {
      dispatch({
        type: types.GET_PURCHASES_SUCCESS,
        payload: purchases,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_PURCHASES_ERROR, error: message });
      return message;
    });
};

export const purchaseLoading = () => (dispatch) => {
  dispatch({ type: types.PURCHASE_TRACK_LOADING });
};

export const purchaseError = (error) => (dispatch) => {
  dispatch({ type: types.PURCHASE_TRACK_ERROR, error: error.message });
};

export const purchaseTrack = (payload) => (dispatch) => {
  dispatch({ type: types.PURCHASE_TRACK_SUCCESS, payload });
};

export const clearActiveTrack = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ACTIVE_TRACK });
};
