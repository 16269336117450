import styled from 'styled-components';

export const StyledHeaderAuth = styled.ul`
  li {
    display: inline-block;

    + li {
      margin-left: 3rem;
    }

    button.link {
      font: inherit;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    .menu {
      display: none;
      position: absolute;
      right: 0;
      padding: 16px;
      background: hsla(0, 0%, 0%, 0.7);
    }

    .menu.open {
      display: block;
    }

    .menu li {
      display: block;

      + li {
        margin: 0;
      }
    }
  }
`;
