import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'redux/store/configureStore';
import { loadState, saveState } from 'redux/store/localStorage';
import { userSignOut } from 'redux/actions/userActions';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axiosInstance from 'api/config/axiosConfig';
import toastr from 'utilities/toastr';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

const persistedState = loadState();
const store = configureStore(persistedState);

store.subscribe(() => {
  // Add in any store state to persist, like cart, favorites, etc
  const { auth, tags, channel, gate } = store.getState();
  if (!auth.user) {
    return saveState({ tags, gate });
  }
  // Add in any store state to persist, like cart, favorites, etc
  saveState({ auth, tags, channel, gate });
});

// Intercept 403 specifically to sign out a user from an area that
// has access to the store (not in config file)
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 403) {
      store.dispatch(userSignOut({ signin: true }));
      toastr.error(
        'Something went wrong and you have been signed out, please sign in and try again'
      );
    }
    return Promise.reject(error);
  }
);

ReactGA.initialize('G-4JM4MKS62P');

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
