import styled from 'styled-components';

export const StyledSiteWrapper = styled.div`
  /* padding-bottom: ${({ theme }) => theme.playerHeight}; */
  opacity: ${({ ready }) => (ready ? 1 : 0)};
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    flex: 1;
    overflow: auto;
  }

  > .content > *:first-child {
    padding-top: var(--search-height);
  }
`;
