import { useEffect, useRef } from 'react';

export const useScrollPosition = () => {
  const scrollRef = useRef(0);

  useEffect(() => {
    const element = document.querySelector('.content');
    const handleScroll = () => (scrollRef.current = element.scrollTop);
    element.addEventListener('scroll', handleScroll, { passive: true });
    return () => element.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollRef.current;
};
