import mixpanel from 'mixpanel-browser';
mixpanel.init('85ca8920d0de1f7478d00d5e8f89536f', { debug: true });

const env_check = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
    set_once: (props) => {
      if (env_check) mixpanel.people.set_once(props);
    },
    increment: (props) => {
      if (env_check) mixpanel.people.increment(props);
    },
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
};

export { actions as mixpanel };
