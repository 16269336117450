import * as channelApi from 'api/channelApi.js';
import { mixpanel } from 'utilities';
import toastr from 'utilities/toastr';

const types = {
  GET_CHANNELS_LOADING: 'GET_CHANNELS_LOADING',
  GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
  GET_CHANNELS_ERROR: 'GET_CHANNELS_ERROR',
  ADD_CHANNEL_LOADING: 'ADD_CHANNEL_LOADING',
  ADD_CHANNEL_SUCCESS: 'ADD_CHANNEL_SUCCESS',
  ADD_CHANNEL_ERROR: 'ADD_CHANNEL_ERROR',
  REMOVE_CHANNEL_LOADING: 'REMOVE_CHANNEL_LOADING',
  REMOVE_CHANNEL_SUCCESS: 'REMOVE_CHANNEL_SUCCESS',
  REMOVE_CHANNEL_ERROR: 'REMOVE_CHANNEL_ERROR',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
};

export const getChannels = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.GET_CHANNELS_LOADING });
    return channelApi
      .getChannels(payload)
      .then((channels) => {
        dispatch({ type: types.GET_CHANNELS_SUCCESS, payload: channels });
      })
      .catch((error) => {
        toastr.error(error.message);
        dispatch({ type: types.GET_CHANNELS_ERROR });
        return error;
      });
  };
};

export const addChannel = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_CHANNEL_LOADING });
    return channelApi
      .addChannel(payload)
      .then((channel) => {
        toastr.success(`Channel saved!`);
        dispatch({ type: types.ADD_CHANNEL_SUCCESS, payload: channel });
        dispatch({
          type: types.USER_UPDATE_SUCCESS,
          payload: { has_yt_channel: true },
        });
        mixpanel.track('Add Channel', {
          'YouTube Channel ID': payload.channel_id,
        });
        mixpanel.people.set({
          'YouTube Channel ID': payload.channel_id,
        });
      })
      .catch((message) => {
        dispatch({ type: types.ADD_CHANNEL_ERROR, error: message });
        return message;
      });
  };
};

export const removeChannel = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_CHANNEL_LOADING });
    return channelApi
      .removeChannel(payload)
      .then((channel) => {
        toastr.success(`Channel removed`);
        dispatch({ type: types.REMOVE_CHANNEL_SUCCESS, payload: channel });
      })
      .catch((message) => {
        dispatch({ type: types.REMOVE_CHANNEL_ERROR, error: message });
      });
  };
};
