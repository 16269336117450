const initialState = {
  messages: null,
  chatId: null,
  tracks: null,
  info: null,
  loading: false,
  historyLoading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AI_SEARCH_CHAT_LOADING':
      return {
        ...state,
        messages: [
          ...(state.messages || []),
          { role: 'user', content: action.payload.message },
        ],
        loading: true,
        error: null,
      };

    case 'GET_AI_CHATS_LOADING':
    case 'DELETE_AI_CHAT_BY_ID_LOADING':
      return {
        ...state,
        historyLoading: true,
        error: null,
      };

    case 'GET_AI_CHAT_BY_ID_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'AI_SEARCH_CHAT_SUCCESS':
    case 'GET_AI_CHAT_BY_ID_SUCCESS':
      return {
        ...state,
        messages: action.payload.messages,
        chatId: action.payload.chat_id,
        tracks: action.payload.tracks,
        info: action.payload.info,
        loading: false,
        error: null,
      };

    case 'GET_AI_CHATS_SUCCESS':
      return {
        ...state,
        chats: action.payload,
        historyLoading: false,
        error: null,
      };

    case 'DELETE_AI_CHAT_BY_ID_SUCCESS':
      return {
        ...state,
        chats: state.chats.filter((chat) => chat.id !== action.payload),
        historyLoading: false,
        error: null,
      };

    case 'AI_SEARCH_CHAT_ERROR':
    case 'GET_AI_CHATS_ERROR':
    case 'GET_AI_CHAT_BY_ID_ERROR':
    case 'DELETE_AI_CHAT_BY_ID_ERROR':
      return {
        ...state,
        loading: false,
        historyLoading: false,
        error: action.error,
      };

    case 'AI_SEARCH_CHAT_RESET':
      return {
        ...initialState,
        chats: state.chats,
      };

    default:
      return state;
  }
};

export default reducer;
