import React from 'react';
import { StyledSearchBar } from './SearchBar.styled';
import SearchButtons from 'common/Button/SearchButton/SearchButtons';
import useSearchBar from './useSearchBar';
import KeywordInput from './KeywordSearch/KeywordInput';
import SimilarityInput from './SimilaritySearch/SimilarityInput';

const SearchBar = () => {
  const { loading, inputRef, selected, user, handleSelect } = useSearchBar();

  const inputLookup = {
    keyword: KeywordInput,
    similar: SimilarityInput,
  };
  const SearchInput = inputLookup[selected];

  return (
    <StyledSearchBar loading={+loading}>
      <div className="search-contents">
        {SearchInput ? <SearchInput ref={inputRef} /> : null}
        {user ? (
          <SearchButtons selected={selected} handleSelect={handleSelect} />
        ) : null}
      </div>
    </StyledSearchBar>
  );
};

export default SearchBar;
