import axios, { setLsToken } from './config/axiosConfig';

export const userSignUp = async (payload) => {
  try {
    const res = await axios.post(`/api/accounts/users/register`, payload);
    const { user, access, refresh } = res.data;

    if (!res.data.access) throw new Error('No token');
    setLsToken({ access, refresh });

    return user;
  } catch (error) {
    throw error;
  }
};

export const userVerify = async ({ token }) => {
  try {
    const res = await axios.get(
      `/api/accounts/users/email-verify?token=${token}`
    );
    const { user } = res.data;

    return user;
  } catch (error) {
    throw error;
  }
};

export const userSignIn = async ({ email, password }) => {
  try {
    const res = await axios.post(`/api/token`, { email, password });
    const { user, access, refresh } = res.data;

    if (!res.data.access) throw new Error('No token');
    setLsToken({ access, refresh });

    return user;
  } catch (error) {
    throw error;
  }
};

export const userForgotPass = async (payload) => {
  try {
    const res = await axios.post(
      `/api/accounts/users/forgot-password`,
      payload
    );
    return res.data.email;
  } catch (error) {
    throw error;
  }
};

export const userCheckResetToken = async (payload) => {
  try {
    await axios.post(`/api/accounts/users/check-reset-token`, payload);
  } catch (error) {
    throw error;
  }
};

export const userResetPass = async (payload) => {
  try {
    const res = await axios.post(`/api/accounts/users/reset-password`, payload);
    return { email: res.data.user.email };
  } catch (error) {
    throw error;
  }
};

export const userActivatePass = async (payload) => {
  try {
    const res = await axios.post(`/api/appsumo/activate`, payload);
    return { email: res.data.user.email };
  } catch (error) {
    throw error;
  }
};

export const userUpdate = async (payload) => {
  const updatedPayload = Object.keys(payload).reduce((obj, key) => {
    if (payload[key]) {
      obj[key] = payload[key];
    }
    return obj;
  }, {});

  try {
    const res = await axios.put(`/api/accounts/users/update`, updatedPayload);
    return res.data?.user;
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = async () => {
  try {
    const res = await axios.get(`/api/accounts/users/current`);
    return res.data?.user;
  } catch (error) {
    throw error;
  }
};
