import React from 'react';
import CreateProjectForm from './CreateProjectForm';

const CreateProject = ({
  user,
  track,
  handleSwitch,
  handleCreateProject,
  loading,
}) => {
  return (
    <>
      <div className="modal-text-header">
        <h2>Create Project</h2>
        <p>
          Add <span className="highlight">{track.title}</span> to a new project
        </p>
      </div>
      <CreateProjectForm
        loading={loading}
        user={user}
        handleSwitch={handleSwitch}
        onSubmit={handleCreateProject}
      />
    </>
  );
};

export default CreateProject;
