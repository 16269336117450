import styled from 'styled-components';

export const StyledPrimaryButton = styled.button`
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  width: ${({ full, width }) => (full ? '100%' : width || 'auto')};

  &:hover {
    filter: brightness(110%);

    .front {
      transform: translateY(-3px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5),
        background-position 300ms;
      background-position: 100% 50%;
    }
    .edge {
      background-position: 100% 50%;
    }
    .shadow {
      transform: translateY(3px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }
  }

  &:active {
    .front {
      transform: translateY(-1px);
      transition: transform 34ms;
    }
    .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }
  .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;

    background: ${({ theme, danger }) =>
        danger
          ? `linear-gradient(
              59deg,
              ${theme.textError},
              ${theme.textError}
            )`
          : `linear-gradient(
      59deg,
      ${theme.highlightSecondary},
      ${theme.highlightPrimary} 20%,
      ${theme.highlightPrimary} 48%,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 48px,
      hsla(0, 0%, 100%, 1) 52%,
      ${theme.highlightPrimary} 0,
      ${theme.highlightSecondary} 80%,
      ${theme.highlightSecondary}
    )`},
      rgba(0, 0, 0, 0.3);
    background-size: 270%;
    background-position: 0 50%;
    background-blend-mode: multiply;
  }
  .front {
    height: ${({ full, height, theme }) =>
      full ? theme.buttonFullHeight : height || '40px'};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 42px;
    border-radius: 4px;
    color: hsla(0, 0%, 100%, 1);

    text-shadow: 0px 2px 1px rgb(0 0 0 / 20%);
    background: ${({ theme, danger }) =>
      danger
        ? theme.textError
        : `linear-gradient(
      59deg,
      ${theme.highlightSecondary},
      ${theme.highlightPrimary} 20%,
      ${theme.highlightPrimary} 48%,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 48px,
      hsla(0, 0%, 100%, 1) 52%,
      ${theme.highlightPrimary} 0,
      ${theme.highlightSecondary} 80%,
      ${theme.highlightSecondary}
    )`};
    background-size: 270%;
    background-position: 0 50%;
    will-change: transform;
    transform: translateY(-2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1),
      background-position 300ms;
  }
`;
