import React from 'react';
import { StyledSearchButtons } from './SearchButtons.styled';
import SearchButton from './SearchButton';

const SearchButtons = ({ selected, handleSelect }) => (
  <StyledSearchButtons>
    <SearchButton
      path="/search"
      name="keyword"
      selected={selected}
      handleClick={handleSelect}
    >
      Keyword Search
    </SearchButton>
    <SearchButton
      path="/search"
      name="similar"
      selected={selected}
      handleClick={handleSelect}
    >
      Sounds Similar
    </SearchButton>
    <SearchButton
      path="/aisearch"
      name="ai"
      selected={selected}
      handleClick={handleSelect}
    >
      <span>
        Persona <span className="highlight">AI</span>
      </span>
      <span className="sub">Powered by ChatGPT</span>
    </SearchButton>
  </StyledSearchButtons>
);

export default SearchButtons;
