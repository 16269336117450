import styled from 'styled-components';

export const StyledProfileImage = styled.span`
  --_size: ${({ small }) => (small ? '2.2rem' : '2.8rem')};
  background-color: ${({ theme }) => theme.textPrimary + '38'};
  background-image: ${({ image }) => image && `url(${image})`};
  background-size: cover;
  color: ${({ theme }) => theme.textPrimary};
  inline-size: var(--_size);
  block-size: var(--_size);
  border-radius: var(--_size);
  display: inline-grid;
  place-items: center;

  & > svg {
    --_svg-size: ${({ full }) => (full ? '100%' : '70%')};
    inline-size: var(--_svg-size);
    block-size: var(--_svg-size);
  }
`;
