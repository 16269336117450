import React from 'react';
import { FormGroup, Label, Error } from '../Form.styled';

const FileField = ({
  name,
  label,
  multiple = false,
  accept,
  handleWave,
  handleChange,
  formik,
}) => (
  <FormGroup>
    <Label>{label}</Label>
    <div className="file-field">
      <div className="file-field-button">
        <span>Choose File</span>
        <input
          type="file"
          name={name}
          multiple={multiple}
          // onChange={(e) => {
          //   const files = multiple ? e.currentTarget.files : e.currentTarget.files[0];
          //   if (name === 'track') {
          //     return handleWave(files)
          //       .then(({ waveform, trackLength }) => {
          //         formik.setFieldValue('waveform', waveform);
          //         formik.setFieldValue('trackLength', trackLength);
          //         return formik.setFieldValue(name, files);
          //       })
          //   }
          //   return formik.setFieldValue(name, files)
          // }}
          onChange={handleChange}
          accept={accept}
          autoComplete="something-new"
        />
      </div>
      <div className="file-field-filename">
        {typeof formik.values[name] === 'string' ? formik.values[name] : ''}
        {!multiple
          ? formik.values[name]
            ? formik.values[name].name
            : 'No file chosen...'
          : !formik.values[name].length
          ? 'No files chosen...'
          : formik.values[name].length === 1
          ? formik.values[name][0].name
          : formik.values[name].length + ' files chosen'}
      </div>
    </div>
    {formik.errors[name] && formik.touched[name] && (
      <Error>{formik.errors[name]}</Error>
    )}
  </FormGroup>
);
export default FileField;
