import * as partnerApi from 'api/partnerApi.js';
import { openModal } from './modalActions';
import toastr from 'utilities/toastr';

const types = {
  GET_PARTNER_LOADING: 'GET_PARTNER_LOADING',
  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  GET_PARTNER_ERROR: 'GET_PARTNER_ERROR',
  GET_PARTNER_PRELOAD: 'GET_PARTNER_PRELOAD',
  CLEAR_PARTNER: 'CLEAR_PARTNER',
};

export const getPartnerById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PARTNER_LOADING });
    return partnerApi
      .getPartner(id)
      .then((partner) => {
        dispatch({ type: types.GET_PARTNER_SUCCESS, payload: partner });
        return partner;
      })
      .catch((message) => {
        toastr.error(message);
        dispatch({ type: types.GET_PARTNER_ERROR, error: message });
        return message;
      });
  };
};

export const preloadPartner = (payload) => (dispatch) => {
  dispatch({ type: types.GET_PARTNER_PRELOAD, payload });
};

export const openPartner = (payload, values) => (dispatch) => {
  dispatch(preloadPartner(payload));
  dispatch(openModal('creator', values));
  return dispatch(getPartnerById(payload.id));
};

export const clearPartner = () => (dispatch) => {
  dispatch({ type: types.CLEAR_PARTNER });
};
