import React, { useState, useEffect } from 'react';
import Header from 'common/Layout/Header/Header';
import Player from 'features/Player/Player';
import SearchBar from '../Header/Search/SearchBar';
import { StyledSiteWrapper } from './SiteWrapper.styled';

const SiteWrapper = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // Prevent FOUT (flash of unstyled text)
    document.fonts.load("16px 'Red Hat Display'").then(() => {
      setReady(true);
    });
  }, []);

  return (
    <StyledSiteWrapper ready={ready}>
      <Header />
      <div className="content">
        {children}
        <SearchBar />
      </div>
      <Player />
    </StyledSiteWrapper>
  );
};

export default SiteWrapper;
