import React, { Suspense, lazy } from 'react';
import Modal from 'common/Modal/Modal';

// No lazy load for these to ensure no rerender of parent modal of lazy loaded component
import SignInModal from 'features/SignInModal/SignInModal';
import SubscribeModal from 'features/SubscribeModal/SubscribeModal';
import ChannelModal from 'features/ChannelModal/ChannelModal';
import ProjectModal from 'features/ProjectModal/ProjectModal';
import DownloadModal from 'features/DownloadModal/DownloadModal';
import PurchaseModal from 'features/PurchaseModal/PurchaseModal';

const SignUpModal = lazy(() => import('features/SignUpModal/SignUpModal'));
const ArtistModal = lazy(() => import('features/ArtistModal/ArtistModal'));
const AlbumModal = lazy(() => import('features/AlbumModal/AlbumModal'));
const PartnerModal = lazy(() => import('features/PartnerModal/PartnerModal'));
const PlaylistModal = lazy(() =>
  import('features/PlaylistModal/PlaylistModal')
);
const ContactModal = lazy(() =>
  import('features/PricingPage/Contact/ContactModal')
);
const StemsModal = lazy(() => import('features/StemsModal/StemsModal'));

export const modals = [
  { type: 'signup', component: SignUpModal },
  { type: 'signin', component: SignInModal },
  { type: 'subscribe', component: SubscribeModal },
  { type: 'channel', component: ChannelModal },
  { type: 'download', component: DownloadModal },
  { type: 'purchase', component: PurchaseModal },
  { type: 'artist', component: ArtistModal, justify: 'top', width: '80%' },
  { type: 'album', component: AlbumModal, justify: 'top', width: '80%' },
  { type: 'creator', component: PartnerModal, justify: 'top', width: '80%' },
  { type: 'playlist', component: PlaylistModal, justify: 'top', width: '80%' },
  { type: 'project', component: ProjectModal },
  { type: 'contact', component: ContactModal },
  { type: 'stems', component: StemsModal },
];

export const modalss = {
  signup: SignUpModal,
  signin: SignInModal,
  subscribe: SubscribeModal,
  channel: ChannelModal,
  download: DownloadModal,
  purchase: PurchaseModal,
  artist: ArtistModal,
  creator: PartnerModal,
  project: ProjectModal,
  contact: ContactModal,
  stems: StemsModal,
};

const Modals = () => (
  <Suspense fallback={<div></div>}>
    {modals.map((modal, i) => (
      <Modal key={i} {...modal}></Modal>
    ))}
  </Suspense>
);

export default Modals;
