import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadTrack } from 'redux/actions/downloadsActions';
import { StyledModalContent } from 'common/Modal/Modal.styled';
import { Button } from 'common/Button';

const DownloadModal = ({ handleClose }) => {
  const { active, downloading } = useSelector((state) => state.downloads);
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(downloadTrack(active)).then((err) => {
      if (!err) handleClose();
    });
  };

  return (
    <StyledModalContent>
      <div className="modal-header">
        <h2>You're Finished!</h2>
        {active && (
          <p style={{ margin: '36px 0 64px' }}>
            Ready to download <strong>{active.title}</strong> by{' '}
            <strong>{active.primary_artists[0].name}</strong>
          </p>
        )}
        <Button outline loading={downloading} onClick={handleDownload}>
          Download
        </Button>
      </div>
    </StyledModalContent>
  );
};

export default DownloadModal;
