import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'redux/reducers/rootReducer';
import { session } from './middleware';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, session)
  );
}
