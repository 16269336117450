import styled from 'styled-components';

export const StyledUserMenu = styled.div`
  cursor: pointer;
`;

export const StyledNavButton = styled.button`
  background: none;
  color: inherit;

  > * {
    vertical-align: middle;
  }

  .menu-profile-image {
    background: ${({ theme }) => theme.textPrimary + '38'};
    color: ${({ theme }) => theme.textPrimary};
    width: 34px;
    height: 34px;
    border-radius: 40px;
    padding: 6px;
  }
  .menu-chevron {
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }
`;

export const StyledDropdown = styled.div`
  min-width: 190px;
  background: #111;
  position: absolute;
  right: 8px;
  top: var(--header-height);
  transition: all 0.2s;
  z-index: 9999;
  padding: 0.45rem;
  box-shadow: 0px 12px 12px -8px #000;
  opacity: 0;
  transform: translateY(-50px);

  &.open {
    opacity: 1;
    transform: translateY(0);
  }

  .user-details {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0.45rem 1.5rem 1rem;
    cursor: default;
    margin-bottom: 0.45rem;

    .user-email {
      font-size: 0.8rem;
      margin-top: 0.25rem;
      opacity: 0.6;
    }
  }

  a {
    display: block;
    padding: 1rem 1.5rem;

    &:hover {
      background: rgba(255, 255, 255, 0.04);
    }

    &.active:not(.sign-out) {
      font-weight: bold;
    }
  }

  .nav-group {
    a {
      padding-left: 2.45rem;
    }

    span {
      padding: 0.82rem 1.5rem 0.2rem;
      font-size: 0.8em;
      margin-top: 4px;
      opacity: 0.6;
      display: block;
    }
  }
`;
