import styled from 'styled-components';

export const StyledSearchButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  block-size: 100%;

  & > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.125rem;
    background-color: transparent;
    padding: 0.2rem 1rem;
    border-radius: 4px;
    font-weight: 600;
    text-align: start;
    margin: 0;
    border: 1px solid transparent;
    background-color: #000;
    opacity: 0.6;
    transition: 160ms;
    line-height: 1;
    white-space: nowrap;

    & .highlight {
      color: #3d60be;
    }

    & > .sub {
      font-weight: normal;
      font-size: 0.8rem;
      opacity: 0.7;
    }

    &:hover {
      background-color: #5555554d;
      opacity: 0.8;
    }

    &.active {
      border-color: #3d60be;
      background-color: #3d60be4d;
      opacity: 1;
    }
  }
`;
export const StyledSearchButton = styled.button`
  --_button-color: #555555;
  background-color: transparent;
  padding: 0.2rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  border: 1px solid #000;
  background-color: #000;
  opacity: 0.6;
  transition: 160ms;
  white-space: nowrap;

  &.active,
  &:hover {
    background-color: #5555554d;
    opacity: 1;
  }

  &:hover {
    opacity: 0.6;
  }

  &.active {
    opacity: 1;
  }
`;
