import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledHeaderNav } from './HeaderNav.styled';
import { HEADER_NAV } from 'constants/navigation';

const HeaderNav = () => {

  const handleClick = (e, disabled) => {
    if (disabled) e.preventDefault();
  }

  return (
    <StyledHeaderNav>
      <ul className="menu">
        {HEADER_NAV.map((item) => (
          <li key={item.path} className={item.disabled ? 'disabled' : undefined}>
            <NavLink exact to={item.path} onClick={(e) => handleClick(e, item.disabled)}>
              {item.title}
            </NavLink>
            {item.disabled && <span>Coming Soon</span>}
          </li>
        ))}
      </ul>
    </StyledHeaderNav>
  );
};

export default HeaderNav;
