import React from 'react';
import Form, { InputField, SubmitButton } from 'common/Form';
import useChannelForm from './useChannelForm';

const ChannelForm = ({ account, handleClose }) => {
  const { formik, loading } = useChannelForm(handleClose);

  return (
    <Form handleSubmit={formik.handleSubmit} account={account}>
      <InputField label="Channel Name" name="channel_name" formik={formik} />
      <InputField label="Channel ID" name="channel_id" formik={formik} />
      <SubmitButton
        outline={account}
        loading={loading}
        loadingLabel="Saving..."
      >
        Continue
      </SubmitButton>
      {handleClose && (
        <>
          <p className="alternate">or</p>
          <p className="alternate">
            <span className="link" onClick={handleClose}>
              Skip this step
            </span>{' '}
            and add it later.
          </p>
        </>
      )}
    </Form>
  );
};

export default ChannelForm;
