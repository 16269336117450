import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useScrollPosition } from 'hooks';
import { openArtist } from 'redux/actions/artistActions';
import { openPartner } from 'redux/actions/partnerActions';
import { openPlaylist } from 'redux/actions/playlistActions';
import { closeModal } from 'redux/actions/modalActions';

const RouteChange = ({ children }) => {
  const { artist: aId, creator: cId, playlist: pId } = useQuery();
  const scrollPosition = useScrollPosition();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const element = document.querySelector('.content');

    const unlisten = history.listen((props) => {
      if (history.action === 'POP') {
        return element.scrollTo(0, scrollPosition);
      }
      if (!props.state?.noScroll) {
        element.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, [history, scrollPosition]);

  useEffect(() => {
    const state = history.location.state;

    if (pId) {
      const playlist = state?.playlist || { id: pId };
      return dispatch(openPlaylist(playlist, state?.values));
    }
    if (aId) {
      const artist = state?.artist || { id: aId };
      return dispatch(openArtist(artist, state?.values));
    }
    if (cId) {
      const creator = state?.creator || { id: cId };
      return dispatch(openPartner(creator, state?.values));
    }
    dispatch(closeModal());
    document.getElementById('modal').style.display = 'none';
  }, [dispatch, history.location.state, aId, cId, pId]);

  return <>{children}</>;
};

export default RouteChange;
