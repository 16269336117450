import styled from 'styled-components';

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  z-index: 999999;
`;

export const StyledHeader = styled.header`
  height: var(--header-height);
  background: ${({ theme }) => theme.backgroundPrimary};
  z-index: 100;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    &:hover,
    &.active {
      color: ${({ theme }) => theme.textHighlight};
    }
  }
  .site-logo {
    inline-size: 12rem;
    color: ${({ theme }) => theme.textHighlight};
    z-index: 99999999999;
    flex-shrink: 0;

    svg {
      -webkit-filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.7));
    }
  }
`;
