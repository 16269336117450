import React from 'react';
import { StyledDisplayButton, StyledButton } from './Button.styled';

const Button = ({ children, loading, ...props }) => {
  if (props.beauty) {
    return (
      <StyledDisplayButton {...props}>
        <span className="shadow"></span>
        <span className="edge"></span>
        <span className="front">{loading ? 'Loading...' : children}</span>
      </StyledDisplayButton>
    );
  }
  return (
    <StyledButton {...props}>{loading ? 'Loading...' : children}</StyledButton>
  );
};

export default Button;
