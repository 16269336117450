import React from 'react';
import { ReactComponent as Favorite } from 'images/icons/favorite.svg';
import { ReactComponent as FavoriteBorder } from 'images/icons/favorite-border.svg';
import { ReactComponent as Plus } from 'images/icons/plus.svg';
import { ReactComponent as Similar } from 'images/icons/similar.svg';
import { ReactComponent as Download } from 'images/icons/download.svg';
import { ReactComponent as Cart } from 'images/icons/shopping-cart.svg';
import { ReactComponent as Chevron } from 'images/icons/chevron-down.svg';
import { ReactComponent as Clear } from 'images/icons/clear.svg';
import { ReactComponent as Stems } from 'images/icons/stems.svg';
import { StyledUserControls } from '../Track.styled';
import { IconButton } from 'common/Button';
import Volume from 'features/Player/Volume/Volume';
import useTrack from '../useTrack';

const Controls = ({
  track,
  slim,
  open,
  volume,
  mobile,
  handleRemove,
  handleDrawer,
  handleVolume,
}) => {
  const {
    paid,
    favorite,
    downloading,
    handleFavorite,
    handleProject,
    handleSimilar,
    handleDownload,
    handleStems,
  } = useTrack(track);

  const alts = +track.alternate_set?.length || track.alternate_count;

  return (
    <StyledUserControls>
      {handleVolume && !mobile && (
        <Volume volume={volume} handleVolume={handleVolume} />
      )}
      <IconButton
        aria-label="add to favorite"
        tooltip={`${!favorite ? 'Add to' : 'Remove from'} favorites`}
        handleClick={handleFavorite}
      >
        {favorite ? (
          <Favorite style={{ color: '#be0e0e' }} />
        ) : (
          <FavoriteBorder />
        )}
      </IconButton>
      <IconButton
        aria-label="add to project"
        tooltip="Add to project"
        handleClick={handleProject}
      >
        <Plus />
      </IconButton>
      <IconButton
        aria-label="similar tracks"
        tooltip="Similar tracks"
        handleClick={handleSimilar}
      >
        <Similar />
      </IconButton>
      <IconButton aria-label="stems" tooltip="Stems" handleClick={handleStems}>
        <Stems />
      </IconButton>
      {track.is_premium && !paid ? (
        <IconButton
          aria-label="purchase track"
          tooltip="Purchase"
          handleClick={handleDownload}
        >
          <Cart />
        </IconButton>
      ) : (
        <IconButton
          aria-label="download track"
          tooltip="Download"
          handleClick={handleDownload}
        >
          <Download className={downloading ? 'loading' : undefined} />
        </IconButton>
      )}

      {!slim && !!handleDrawer && (
        <IconButton
          aria-label="open more"
          tooltip="More"
          handleClick={handleDrawer}
        >
          {!!alts && <span className="badge">+{alts}</span>}
          <Chevron className={open ? 'open' : undefined} />
        </IconButton>
      )}
      {handleRemove && (
        <IconButton
          aria-label="remove track"
          tooltip="Remove track"
          handleClick={handleRemove}
        >
          <Clear />
        </IconButton>
      )}
    </StyledUserControls>
  );
};

export default Controls;
