import React, { useState, useEffect, useRef, useMemo } from 'react';
import SvgWave from './SvgWave';
import SvgWaveOverlay from './SvgWaveOverlay';
import { numToTime } from 'utilities';
import { StyledWaveform } from './Waveform.styled';

const Waveform = ({
  id,
  init,
  peaks,
  progress,
  color,
  handleSeek,
  trackLength,
  loading,
  large,
}) => {
  const [position, setPosition] = useState(0);
  const [width, setWidth] = useState();
  const waveRef = useRef();

  const memoizedWidth = useMemo((w) => setWidth(() => w), []);

  useEffect(() => {
    const waveform = waveRef.current;

    const cbFunc = ([entry]) => {
      setWidth(Math.round(entry.contentRect.width / 5) * 5);
    };
    const observer = new ResizeObserver(cbFunc);
    if (waveform) observer.observe(waveform);
    return () => {
      if (waveform) observer.unobserve(waveform);
    };
  }, [memoizedWidth]);

  const handlePos = (e) => {
    const details = e.target.getBoundingClientRect();
    const clickX = e.clientX;
    const value = (clickX - details.left) / details.width;
    const formattedVal = Math.round(value * 100) / 100;
    setPosition(formattedVal);
  };

  return (
    <StyledWaveform id={'waveform-' + id} ref={waveRef}>
      {handleSeek && (
        <SvgWaveOverlay
          tooltip={`${init ? 'Skip to' : 'Start at'} ${numToTime(
            position * trackLength
          )}`}
          handlePos={handlePos}
          handleSeek={(e) => handleSeek(e, position)}
        />
      )}
      <SvgWave
        large={large}
        color={color}
        width={waveRef.current?.clientWidth || width}
        peaks={loading ? [0, 0] : peaks}
        progress={progress || 0}
      />
    </StyledWaveform>
  );
};

export default Waveform;
