import React, { useLayoutEffect, useState } from 'react';
import { StyledSecondary } from './Modal.styled';
import ModalClose from './ModalClose';

const SecondaryModal = ({ component: Component, values, handleClose }) => {
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    setReady(true);
  }, []);

  return !Component ? null : (
    <StyledSecondary ready={ready} onClick={handleClose}>
      <div className="secondary-wrapper" onClick={(e) => e.stopPropagation()}>
        <ModalClose handleClick={handleClose} />
        <Component {...values} handleClose={handleClose} />
      </div>
    </StyledSecondary>
  );
};
export default SecondaryModal;
