import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addFavorite, removeFavorite } from "redux/actions/favoritesActions";
import { setProjectTrack } from "redux/actions/projectsActions";
import { downloadTrack } from "redux/actions/downloadsActions";
import {
  setNextTrack,
  setPreviousTrack,
  closePlayer,
} from "redux/actions/playerActions";
import { useAudio } from "hooks";
import { useHistory } from "react-router-dom";

const usePlayer = () => {
  const { track, index, queue, loading } = useSelector((state) => state.player);
  const { downloading } = useSelector((state) => state.downloads);
  const { favorites } = useSelector((state) => state.favorites);
  const { controls, loaded, playing } = useAudio();
  const [volume, setVolume] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    controls.volume(volume);
  }, [volume, controls]);

  const handleFavorite = () => {
    if (favorites.some((f) => f === track.id)) {
      return dispatch(removeFavorite(track.id));
    }
    dispatch(addFavorite(track.id));
  };

  const handleProject = () => {
    dispatch(setProjectTrack(track));
  };

  const handleDownload = () => {
    dispatch(downloadTrack(track));
  };

  const handlePause = () => {
    controls.pause();
  };

  const handleSeek = (percent) => {
    controls.seek(percent);
  };

  const handleVolume = (value) => {
    setVolume(value);
  };

  const handleArtist = (id) => {
    history.push(`/artist/${id}`);
  };

  const handleAlbum = (id) => {
    history.push(`/album/${id}`);
  };

  const handlePrev = () => {
    if (queue.length <= 1) return;
    const previousIndex = index - 1 < 0 ? queue.length - 1 : index - 1;
    dispatch(setPreviousTrack(previousIndex));
    controls.load(queue[previousIndex].asset_src);
  };

  const handleNext = () => {
    if (queue.length <= 1) return;
    const nextIndex = queue.length - 1 === index ? 0 : index + 1;
    dispatch(setNextTrack(nextIndex));
    controls.load(queue[nextIndex].asset_src);
  };

  const handleClose = () => {
    controls.stop();
    dispatch(closePlayer());
  };

  return {
    player: {
      track,
      queue,
      loading: !loaded || loading,
      playing,
      volume,
      downloading: downloading === track?.id,
    },
    favorites,
    actions: {
      handleFavorite,
      handleProject,
      handleDownload,
      handleArtist,
      handleAlbum,
    },
    controls: {
      handlePause,
      handleSeek,
      handlePrev,
      handleNext,
      handleClose,
      handleVolume,
    },
  };
};

export default usePlayer;
