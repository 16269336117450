import styled from 'styled-components';
import { device } from 'styles/device';

export const StyledHeaderNav = styled.nav`
  flex: 1;
  padding: 0 3rem;

  .menu li {
    display: inline-block;
    position: relative;

    + li {
      margin-left: 3rem;
    }
    a {
      &.active {
        font-weight: bold;
      }
    }
    span {
      position: absolute;
      top: 18px;
      font-size: 0.55rem;
      opacity: 0.5;
      font-weight: bold;
      left: 0;
      width: 100%;
      text-align: center;
      cursor: default;
    }
    &.disabled a:hover {
      color: inherit;
      cursor: default;
    }
  }

  @media ${device.mobile} {
    .menu {
      display: none;
      position: absolute;
      right: 0;
      padding: 16px;
      background: hsla(0, 0%, 0%, 0.7);
    }

    .menu li {
      display: block;

      + li {
        margin: 0;
      }
    }
  }
`;
