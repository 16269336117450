import * as artistApi from 'api/artistApi.js';
import { openModal } from './modalActions';
import toastr from 'utilities/toastr';

const types = {
  GET_ARTIST_LOADING: 'GET_ARTIST_LOADING',
  GET_ARTIST_SUCCESS: 'GET_ARTIST_SUCCESS',
  GET_ARTIST_ERROR: 'GET_ARTIST_ERROR',
  GET_ARTIST_PRELOAD: 'GET_ARTIST_PRELOAD',
  CLEAR_ARTIST: 'CLEAR_ARTIST',
};

export const getArtistById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_ARTIST_LOADING });
    return artistApi
      .getArtist(id)
      .then((artist) => {
        dispatch({ type: types.GET_ARTIST_SUCCESS, payload: artist });
      })
      .catch((message) => {
        toastr.error(message);
        dispatch({ type: types.GET_ARTIST_ERROR, error: message });
        return message;
      });
  };
};

export const preloadArtist = (payload) => (dispatch) => {
  dispatch({ type: types.GET_ARTIST_PRELOAD, payload });
};

export const openArtist = (payload, values) => (dispatch) => {
  dispatch(preloadArtist(payload));
  dispatch(openModal('artist', values));
  return dispatch(getArtistById(payload.id));
};

export const clearArtist = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ARTIST });
};
