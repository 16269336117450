import styled from 'styled-components';

export const StyledSubDetails = styled.div`
  margin-block-end: 3.6rem;
  .pricing-row {
    display: flex;
    justify-content: space-between;
    margin-block-end: 0.65rem;
    &.total {
      color: ${({ theme }) => theme.textHighlight};
      font-size: 1.2rem;
      font-weight: bold;
    }
    p {
      margin: 0;
      &.green {
        color: #1aa414;
      }
    }
  }
  hr {
    border: none;
    border-bottom: 1px solid #ffffff55;
    margin-block: 1.2rem;
  }
`;
