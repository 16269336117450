import { imagePaths, imagePaths400 } from 'images/cards/index.js';

export { randomTags } from './randomTags.js';
export { loadBuffer } from './buffer-loader.js';
export { loadSource } from './source-loader.js';
export { soundTouch } from './soundtouch.js';
export { renderMetaPixel } from './renderMetaPixel.js';

export {
  getLsToken,
  setLsToken,
  clearLsToken,
  clearLsStore,
} from './localStorage';
export { mixpanel } from './mixpanel';

export const randomDefaultImage = () => {
  return imagePaths[Math.floor(Math.random() * imagePaths.length)];
};

export const randomDefault400Image = () => {
  return imagePaths400[Math.floor(Math.random() * imagePaths.length)];
};

export const numToTime = (num) => {
  const mins = Math.floor(num / 60);
  const secs = String(Math.round(num % 60));
  return `${mins}:${secs.length !== 1 ? secs : '0' + secs}`;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const couponValue = (coupon) => {
  if (coupon?.amount_off) {
    return `$${coupon?.amount_off}`;
  }
  if (coupon?.percent_off) {
    return `${coupon?.percent_off}%`;
  }
  return false;
};

// input: { inc: [{tag}], exc: [{tag}]}
export const formatTags = (tags) => {
  return Object.entries(tags).reduce((obj, [type, items]) => {
    console.log(items);
    // Iterate through each item in the 'inc' and 'exc' arrays
    items.forEach((item) => {
      // Generate the key for the formatted tag based on the item type and 'inc'/'exc'
      const key = `${item.type}s_${type}`;
      // Append the item ID to the corresponding key's array in the 'obj' object
      obj[key] = [...(obj[key] || []), item.id];
    });
    // Return the updated 'obj' object for the next iteration
    return obj;
  }, {});
};
