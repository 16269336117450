import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import { GlobalStyles } from 'styles/global';
import { theme } from 'styles/theme';
import 'toastr/build/toastr.min.css';

const ThemeProvider = ({ children }) => (
  <Provider theme={theme.dark}>
    <GlobalStyles />
    {children}
  </Provider>
);

export { ThemeProvider };
