import * as partnerApi from 'api/partnerApi';
import * as playlistApi from 'api/playlistApi';
import * as trackApi from 'api/trackApi';

const types = {
  GET_HOME_PARTNERS_LOADING: 'GET_HOME_PARTNERS_LOADING',
  GET_HOME_PARTNERS_SUCCESS: 'GET_HOME_PARTNERS_SUCCESS',
  GET_HOME_PARTNERS_ERROR: 'GET_HOME_PARTNERS_ERROR',
  GET_HOME_PLAYLISTS_LOADING: 'GET_HOME_PLAYLISTS_LOADING',
  GET_HOME_PLAYLIST_SUCCESS: 'GET_HOME_PLAYLIST_SUCCESS',
  GET_HOME_PLAYLISTS_SUCCESS: 'GET_HOME_PLAYLISTS_SUCCESS',
  GET_HOME_PLAYLISTS_ERROR: 'GET_HOME_PLAYLISTS_ERROR',
  GET_HOME_TRACKS_LOADING: 'GET_HOME_TRACKS_LOADING',
  GET_HOME_TRACKS_SUCCESS: 'GET_HOME_TRACKS_SUCCESS',
  GET_HOME_TRACKS_ERROR: 'GET_HOME_TRACKS_ERROR',
  GET_MORE_HOME_TRACKS_LOADING: 'GET_MORE_HOME_TRACKS_LOADING',
  GET_MORE_HOME_TRACKS_SUCCESS: 'GET_MORE_HOME_TRACKS_SUCCESS',
  GET_MORE_HOME_TRACKS_ERROR: 'GET_MORE_HOME_TRACKS_ERROR',
  CHANGE_HOME_SORT_ORDER: 'CHANGE_HOME_SORT_ORDER',
  CLEAR_HOME_TRACKS: 'CLEAR_HOME_TRACKS',
};

export const getLandingPartners = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_HOME_PARTNERS_LOADING });
    return partnerApi
      .getPartners({ limit: 4 })
      .then(({ results }) => {
        dispatch({
          type: types.GET_HOME_PARTNERS_SUCCESS,
          payload: results,
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_HOME_PARTNERS_ERROR, error: message });
        return message;
      });
  };
};

export const getHomePlaylists = () => (dispatch) => {
  const getPlaylists = ({ ptype, category }) =>
    playlistApi
      .getPlaylists({ limit: 15, ptype, order: '?' })
      .then(({ results }) => {
        dispatch({
          type: types.GET_HOME_PLAYLIST_SUCCESS,
          payload: results,
          category,
        });
      });

  const calls = [
    getPlaylists({ ptype: 'mixed', category: 'recommended' }),
    getPlaylists({ ptype: 'creator', category: 'curated' }),
    getPlaylists({ ptype: 'artist', category: 'music' }),
  ];

  dispatch({ type: types.GET_HOME_PLAYLISTS_LOADING });

  return Promise.all(calls)
    .then(() => {
      dispatch({ type: types.GET_HOME_PLAYLISTS_LOADING });
    })
    .catch((message) => {
      dispatch({ type: types.GET_HOME_PLAYLISTS_ERROR, error: message });
      return message;
    });
};

export const getHomeTracks = () => (dispatch, getState) => {
  const { limit, ordering } = getState().home;

  dispatch({ type: types.GET_HOME_TRACKS_LOADING });
  return trackApi
    .getTracks({ limit, ordering })
    .then((results) => {
      dispatch({
        type: types.GET_HOME_TRACKS_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_HOME_TRACKS_ERROR, error: message });
      return message;
    });
};

export const getMoreHomeTracks = () => (dispatch, getState) => {
  const { limit, nextPage, ordering } = getState().home;

  dispatch({ type: types.GET_MORE_HOME_TRACKS_LOADING });
  return trackApi
    .getTracks({ limit, nextPage, ordering })
    .then((results) => {
      dispatch({
        type: types.GET_MORE_HOME_TRACKS_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_MORE_HOME_TRACKS_ERROR, error: message });
      return message;
    });
};

export const getHomeContent = () => (dispatch) => {
  dispatch(getHomePlaylists());
  dispatch(getHomeTracks());
};

export const changeOrder = (payload) => (dispatch) => {
  dispatch({ type: types.CHANGE_HOME_SORT_ORDER, payload });
  dispatch(getHomeTracks());
};

export const clearHomeTracks = () => (dispatch) => {
  dispatch({ type: types.CLEAR_HOME_TRACKS });
};
