import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import { FormGroup, Label, Error } from '../Form.styled';

const RECAPTCHA_SITE = process.env.REACT_APP_RECAPTCHA_SITE;

const RecaptchaField = ({ label, name, formik }) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    <Recaptcha
      className="recaptcha"
      sitekey={RECAPTCHA_SITE}
      onChange={(token) => formik.setFieldValue(name, token)}
      theme="dark"
    />
    {formik.errors[name] && formik.touched[name] && (
      <Error>{formik.errors[name]}</Error>
    )}
  </FormGroup>
);

export default RecaptchaField;
