import * as subscriptionApi from 'api/subscriptionApi';
import { couponValue } from 'utilities';
import toastr from 'utilities/toastr';

const types = {
  INVITE_CODE_LOADING: 'INVITE_CODE_LOADING',
  INVITE_CODE_SUCCESS: 'INVITE_CODE_SUCCESS',
  INVITE_CODE_ERROR: 'INVITE_CODE_ERROR',
};

export const setGateAccess = (coupon) => (dispatch) => {
  dispatch({ type: types.INVITE_CODE_SUCCESS, payload: coupon });
};

export const setCoupon = (coupon) => (dispatch) => {
  dispatch({ type: types.INVITE_CODE_SUCCESS, payload: coupon });
};

export const confirmInviteCode = (code) => (dispatch) => {
  dispatch({ type: types.INVITE_CODE_LOADING });

  return subscriptionApi
    .checkPromoCode(code)
    .then((coupon) => {
      const value = couponValue(coupon);

      dispatch(setGateAccess(coupon));
      toastr.success(
        `Welcome to Persona! A ${value} discount will be applied to your subscription at checkout!`
      );
    })
    .catch((error) => {
      dispatch({ type: types.INVITE_CODE_ERROR, error });
      return error;
    });
};

export const confirmPromoCode = (code) => (dispatch) => {
  dispatch({ type: types.INVITE_CODE_LOADING });

  return subscriptionApi
    .checkPromoCode(code)
    .then((coupon) => {
      dispatch(setGateAccess(coupon));
    })
    .catch((error) => {
      dispatch({ type: types.INVITE_CODE_ERROR, error });
      return error;
    });
};
