import React from 'react';
import { FormGroup, Textarea, Label, Error } from '../Form.styled';

const TextareaField = ({
  label,
  name,
  type = 'text',
  placeholder,
  formik,
  ...rest
}) => (
  <FormGroup>
    <Label>{label}</Label>
    <Textarea
      id={name}
      rows="4"
      type={type}
      name={name}
      placeholder={placeholder}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      autoComplete="something-new"
      {...rest}
    ></Textarea>
    {formik.errors[name] && formik.touched[name] && (
      <Error>{formik.errors[name]}</Error>
    )}
  </FormGroup>
);

export default TextareaField;
