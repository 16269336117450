import styled from 'styled-components';
import landingImage from 'images/backgrounds/landing-hero.jpg';
import { device } from 'styles/device';

export const StyledSecondary = styled.div`
  --modal-margin: 76px;
  background: rgba(15, 15, 15, 0.7);
  position: fixed;
  inset: 0;
  z-index: 2147483647;
  overflow: auto;
  padding-top: 5.4rem;
  /* backdrop-filter: blur(2px); */

  & > .secondary-wrapper {
    position: absolute;
    transform: translateX(-50%);
    inset-inline-start: 50%;
    opacity: ${({ ready }) => +ready};
    margin-top: ${({ ready }) => (ready ? 0 : '100px')};
    transition: opacity 0.2s, margin 0.3s;

    & > div {
      margin-bottom: var(--modal-margin);
    }
  }
`;

export const StyledModal = styled.div`
  --modal-margin: 76px;
  z-index: 999999999999999999;
  padding: 4%;
  padding-top: var(--modal-margin);
  text-align: center;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ ready }) => (ready ? 1 : 0)};
  transition: opacity 0.2s;

  &.top {
    align-items: flex-start;
    padding-top: 5.4rem;
  }
  .modal {
    max-width: 100%;
    display: inline-block;
    background: ${({ theme }) => theme.backgroundPrimary};
    position: relative;
    box-shadow: 0px 10px 50px -10px #000;
    transform: ${({ ready }) => (ready ? 'initial' : 'translateY(60px)')};
    transition: transform 0.2s;
  }

  @media ${device.tablet} {
    &.top {
      padding: 4%;
    }
    .modal {
      width: 100% !important;
    }
  }
`;

export const StyledModalContent = styled.div`
  padding: 24px;
  width: 500px;
  max-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundPrimary};
  text-align: center;

  .modal-header {
    margin: 16px 0 48px;

    h2 {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
  .price {
    sup {
      vertical-align: text-top;
      font-size: 1rem;
      font-weight: normal;
    }
  }
`;

export const StyledModalClose = styled.button`
  position: absolute;
  background: ${({ color }) => color || '#000'};
  right: 8px;
  top: 8px;
  border-radius: 50%;
  padding: 2px;
  transform: rotate(45deg);
  color: #fff;
  opacity: 0.5;
  transition: all 200ms;
  z-index: 999;

  &:hover {
    opacity: 1;
  }
  svg {
    display: block;
  }
`;

export const StyledModalPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(${landingImage});
  background-size: cover;
  background-position: 50%;
  min-height: calc(100vh - 66px);
  overflow: hidden;
  /* background-blend-mode: darken; */
  box-shadow: inset 50px -18px 32px 12px #000000,
    inset 150px -80px 150px 0px #000000;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 132px;
`;
