import React, { StrictMode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SiteWrapper from 'common/Layout/SiteWrapper/SiteWrapper';
import Routes from 'routes/Routes';
import Modals from 'modals/Modals';
import { ThemeProvider } from 'context/ThemeContext';
import { clearSiteContent, getCurrentUser } from 'redux/actions/userActions';
import { getLsToken } from 'utilities';

const App = () => {
  const dispatch = useDispatch();
  const { access } = getLsToken();

  useEffect(() => {
    if (access) {
      dispatch(getCurrentUser());
    }
    dispatch(clearSiteContent());
  }, [dispatch, access]);

  return (
    <StrictMode>
      <ThemeProvider>
        <SiteWrapper>
          <Routes />
        </SiteWrapper>
        <Modals />
      </ThemeProvider>
    </StrictMode>
  );
};
export default App;
