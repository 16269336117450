import React from 'react';
import { ReactComponent as Clear } from 'images/icons/clear.svg';
import { StyledPlayer, StyledPlayerDetails } from './Player.styled';
import PlaybackControls from './Controls/PlaybackControls';
import ProgressComponents from './Progress/ProgressComponents';
import Controls from 'common/Tracks/Controls/Controls';
import { useViewport } from 'hooks';
import usePlayer from './usePlayer';

const Player = () => {
  const { player, actions, controls } = usePlayer();
  const { track, queue, loading, playing, volume } = player;
  const { handleArtist, handleAlbum } = actions;
  const { handlePause, handleSeek, handlePrev } = controls;
  const { handleNext, handleClose, handleVolume } = controls;
  const { tablet, mobile } = useViewport();

  return (
    <StyledPlayer id="player" className={!track ? 'hide' : ''}>
      <audio id="central-audio"></audio>
      {track && (
        <>
          <StyledPlayerDetails albumArt={track.album_cover_src}>
            {!tablet && (
              <div
                className="album-art"
                onClick={() => handleAlbum(track.album_id)}
              ></div>
            )}
            <div className="details">
              <div className="title">{track.title}</div>
              <div className="artist">
                {track.primary_artists.map((artist, i) => [
                  i > 0 && ', ',
                  <span key={i} onClick={() => handleArtist(artist.id)}>
                    {artist.name}
                  </span>,
                ])}
              </div>
            </div>
          </StyledPlayerDetails>
          <PlaybackControls
            border
            mobile={mobile}
            loading={loading}
            playing={playing}
            disable={queue.length <= 1}
            handlePause={handlePause}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
          <ProgressComponents
            mobile={tablet}
            track={track}
            handleSeek={handleSeek}
          />
          <Controls
            track={track}
            slim
            mobile={mobile}
            volume={volume}
            handleVolume={handleVolume}
          />
          <Clear className="close" onClick={handleClose} />
        </>
      )}
    </StyledPlayer>
  );
};

export default Player;
