import { useCallback, useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription } from 'api/subscriptionApi';
import {
  clearSubscriptionError,
  confirmSubscription,
  subscriptionCreate,
  subscriptionError,
  subscriptionLoading,
  updatePaymentMethod,
} from 'redux/actions/subscriptionActions';
import { SUBSCRIPTION_TYPES } from 'constants/subscriptions';
import { confirmPromoCode } from 'redux/actions/gateActions';

const useCheckoutForm = (handleNext) => {
  const { user } = useSelector((state) => state.auth);
  const { values } = useSelector((state) => state.modal);
  const { coupon, error: promoError } = useSelector((state) => state.gate);
  const { loading, errors } = useSelector((state) => state.subscription);
  const { subscription: sub, email } = user;
  const [localError, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const free = coupon?.percent_off === 100;

  const createSubWithPayment = async (details) => {
    const { client_secret, user } = await createSubscription({
      name: SUBSCRIPTION_TYPES[formik.values.plan].name,
      code: coupon?.code,
    });
    const payload = await stripe.confirmCardPayment(client_secret, {
      payment_method: details,
    });
    const paymentMethodId = {
      payment_method_id: payload.paymentIntent?.payment_method,
    };
    if (payload.error) throw payload.error;
    return dispatch(confirmSubscription(user, paymentMethodId));
  };

  const updatePayment = async (details) => {
    const update = await stripe.createPaymentMethod(details);
    if (update.error) throw update.error;
    const paymentMethodId = { payment_method_id: update?.paymentMethod?.id };
    await dispatch(updatePaymentMethod(paymentMethodId));
  };

  const handlePayment = async () => {
    const process = sub?.type ? updatePayment : createSubWithPayment;
    const details = {
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: { email },
    };

    if (!stripe || !elements || !cardComplete) return;
    if (localError) return elements.getElement('card').focus();

    dispatch(subscriptionLoading());

    try {
      await process(details);
      elements.getElement('card').clear();
      handleNext?.();
    } catch (error) {
      dispatch(subscriptionError(error.message));
    }
  };

  const handleFreeSub = () => {
    dispatch(
      subscriptionCreate({
        name: SUBSCRIPTION_TYPES[formik.values.plan].name,
        code: coupon?.code,
      })
    ).then(() => {
      handleNext?.();
    });
  };

  const handleSubmit = () => {
    return free ? handleFreeSub() : handlePayment();
  };

  const formik = useFormik({
    initialValues: { plan: values?.active || 0, code: '' },
    onSubmit: handleSubmit,
  });
  const { setFieldValue, setErrors } = formik;

  const handleCode = useCallback(
    (code) => {
      dispatch(confirmPromoCode(code));
    },
    [dispatch]
  );

  const handleCCChanges = (e) => {
    setError(e.error?.message);
    setCardComplete(e.complete);
  };

  useEffect(() => {
    if (coupon?.code) {
      setFieldValue('code', coupon?.code);
    }
  }, [coupon?.code, handleCode, setFieldValue]);

  useEffect(() => {
    if (promoError) {
      setErrors({ code: promoError });
    }
  }, [promoError, setErrors]);

  useEffect(() => {
    return () => dispatch(clearSubscriptionError());
  }, [dispatch]);

  return {
    formik,
    elements,
    coupon,
    free,
    sub,
    loading: loading.payment,
    error: localError || errors.payment,
    handleCode,
    handleCCChanges,
    disabled: !stripe || !cardComplete || loading.payment,
    applyDisabled: !formik.values.code || formik.values.code === coupon?.code,
  };
};

export default useCheckoutForm;
