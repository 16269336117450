import * as tagApi from 'api/tagApi';

const types = {
  GET_TAGS_LOADING: 'GET_TAGS_LOADING',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
  GET_TAGS_ERROR: 'GET_TAGS_ERROR',
  CLEAR_TAGS: 'CLEAR_TAGS',
};

export const getTags = () => (dispatch) => {
  dispatch({ type: types.GET_TAGS_LOADING });

  return tagApi
    .getTags()
    .then((tags) => {
      dispatch({
        type: types.GET_TAGS_SUCCESS,
        payload: tags,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_TAGS_ERROR, error: message });
      return message;
    });
};

export const clearTags = () => (dispatch) => {
  dispatch({ type: types.CLEAR_TAGS });
};
