import { useState, useEffect } from 'react';

const usePathString = ({ peaks, width, large }) => {
  const [pathString, setPathString] = useState();

  useEffect(() => {
    if (!peaks) return;

    const height = large ? 50 : 30;
    const mid = height / 2;
    const spacing = large ? 0.9 : 0.6;
    let tempString = '';

    for (let i = 0; i < width / spacing; i++) {
      const item = 2 * Math.round((i * spacing * (peaks.length / width)) / 2);
      const h =
        mid + (mid * peaks[item >= peaks.length ? item - 1 : item]) / 100;
      tempString += ` L ${i * spacing} ${i % 2 === 0 ? height - h : h}`;
    }
    setPathString(`M 0 ${mid}${tempString} L ${width} ${mid}`);
  }, [peaks, large, width]);

  return { pathString };
};

export default usePathString;
