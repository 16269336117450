import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateProject from './Create/CreateProject';
import AddToProject from './Add/AddToProject';
import {
  createProjectAddTrack,
  addTrackToProject,
} from 'redux/actions/projectsActions';
import { StyledProjectModal } from './ProjectModal.styled';

const ProjectModal = ({ track, handleClose }) => {
  const { projects, loading } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projects.length) return setState(true);
  }, [projects]);

  const handleSwitch = () => setState(!state);

  const handleAddToProject = ({ project: id }) => {
    dispatch(addTrackToProject({ id, trackId: track.id })).then(() =>
      handleClose()
    );
  };
  const handleCreateProject = (values) => {
    dispatch(createProjectAddTrack({ trackId: track.id, ...values })).then(() =>
      handleClose()
    );
  };

  return (
    <StyledProjectModal>
      {!state ? (
        <CreateProject
          loading={loading}
          user={user}
          track={track}
          handleSwitch={projects?.length ? handleSwitch : false}
          handleCreateProject={handleCreateProject}
        />
      ) : (
        <AddToProject
          loading={loading}
          projects={projects}
          track={track}
          handleSwitch={handleSwitch}
          handleAddToProject={handleAddToProject}
        />
      )}
    </StyledProjectModal>
  );
};

export default ProjectModal;
