import React from 'react';
import {
  StyledSearchLoadingGroup,
  StyledSearchLoadingItem,
} from '../SearchBar.styled';

const SearchResultsLoading = () => (
  <StyledSearchLoadingGroup>
    <h5 className="content-loading">&nbsp;</h5>
    {[...Array(4)].map((_, i) => (
      <StyledSearchLoadingItem key={i}>
        <div className="thumbnail content-loading"></div>
        <div className="details">
          <div className="title content-loading"></div>
        </div>
      </StyledSearchLoadingItem>
    ))}
    <p className="view-all">
      <span className="content-loading">&nbsp;</span>
    </p>
  </StyledSearchLoadingGroup>
);

export default SearchResultsLoading;
