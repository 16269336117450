import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Form, {
  InputField,
  TextareaField,
  FormError,
  SubmitButton,
  FileField,
  CheckboxFields,
} from 'common/Form';

const initialValues = {
  name: '',
  description: '',
  isFeatured: false,
  image: '',
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Your project needs a name';
  }
  if (values.isFeatured && !values.image) {
    errors.image = 'Featured collections require an image';
  }
  return errors;
};

const CreateCollectionForm = ({
  loading,
  error,
  handleSwitch,
  onSubmit,
  user,
}) => {
  const formik = useFormik({ initialValues, validate, onSubmit });
  const [featured, setFeatured] = useState(false);

  useEffect(() => {
    if (!formik.values.isFeatured) {
      formik.setFieldValue('image', '');
    }
    setFeatured(formik.values.isFeatured);
    // TODO: rework this area
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isFeatured]);

  const handleImageChange = (e) => {
    const files = e.target.files.length ? e.target.files[0] : '';
    return formik.setFieldValue(e.target.name, files);
  };

  return (
    <Form handleSubmit={formik.handleSubmit}>
      <InputField label="Name" name="name" formik={formik} />
      <TextareaField label="Description" name="description" formik={formik} />
      {user.isAdmin && (
        <>
          {featured && (
            <FileField
              label="Collection Image"
              name="image"
              accept="image/png, image/jpeg"
              handleChange={handleImageChange}
              formik={formik}
            />
          )}
          <CheckboxFields
            fields={[
              {
                name: 'isFeatured',
                label: 'Featured collection',
              },
            ]}
            formik={formik}
          />
        </>
      )}
      <FormError error={error} />
      <SubmitButton loadingLabel="Saving..." loading={loading}>
        Create
      </SubmitButton>
      {handleSwitch && (
        <p className="alternate">
          Or add to an{' '}
          <span className="link" onClick={handleSwitch}>
            existing collection
          </span>
        </p>
      )}
    </Form>
  );
};

export default CreateCollectionForm;
