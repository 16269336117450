const PersonalTooltip = () => (
  <>
    <h3>Personal</h3>
    <p>
      For usage on all social and user generated content platforms, including:
    </p>
    <ul>
      <li>Unlimited use </li>
      <li>Full access to music library</li>
      <li>Full access to top creator playlists</li>
      <li>Tracks downloadable by stems for easy mixing</li>
      <li>New music and playlists dropping all the time</li>
      <li>
        All video types and platforms except for feature film, Broadcast TV, and
        VOD
      </li>
    </ul>
  </>
);

const CreatorTooltip = () => (
  <>
    <h3>Creator</h3>
    <p>
      For usage on all social and user generated content platforms, including:
    </p>
    <ul>
      <li>Unlimited use </li>
      <li>Full access to music library</li>
      <li>Full access to top creator playlists</li>
      <li>Tracks downloadable by stems for easy mixing</li>
      <li>New music and playlists dropping all the time</li>
      <li>
        All video types and platforms except for feature film, Broadcast TV, and
        VOD
      </li>
    </ul>
  </>
);

export const SUBSCRIPTION_TOOLTIPS = {
  personal: PersonalTooltip,
  creator: CreatorTooltip,
};

export const SUBSCRIPTION_TYPES = [
  // TODO: get these from the database or constants
  { name: 'Personal', type: 'personal', month: 11.99, year: 143.88 },
  { name: 'Creator', type: 'creator', month: 19.99, year: 239.88 },
  {
    name: 'Enterprise',
    type: 'enterprise',
    month: 96.6,
    year: 299,
    disabled: true,
  },
];
