import { StyledButton } from 'common/Button/Button.styled';
import styled from 'styled-components';

export const StyledKeywordInput = styled.form`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline-end: 16px;

  ${StyledButton} {
    inline-size: 5rem;
    block-size: auto;
    padding: 0.5rem 0;
  }
`;

export const StyledPills = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-inline-start: ${({ content }) => content && '16px'};
`;

export const StyledPill = styled.div`
  --_background_opacity: 0.7;
  color: #fff;
  background: #333;
  color: inherit;
  background-attachment: fixed;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
`;
