import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  StyledUserMenu,
  StyledDropdown,
  StyledNavButton,
} from './UserMenu.styled';
import { ReactComponent as Chevron } from 'images/icons/chevron-down.svg';
import { HEADER_NAV, HEADER_AUTH } from 'constants/navigation';
import ProfileImage from 'common/ProfileImage/ProfileImage';

const MenuLoggedIn = ({ user, tablet, handleSignOut }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const toggleButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (open) {
      return setShow(true);
    }
    return setShow(false);
  }, [open]);

  useEffect(() => {
    if (open && dropdownRef.current) {
      const closeExternal = (e) => {
        if (
          !dropdownRef.current.contains(e.target) &&
          !toggleButtonRef.current.contains(e.target)
        ) {
          return setOpen(false);
        }
      };
      document.addEventListener('mousedown', closeExternal);
      setOpen(true);
      return () => document.removeEventListener('mousedown', closeExternal);
    }
  }, [open]);

  const toggleOpen = () => setOpen((open) => !open);

  const handleClick = (e) => {
    e.preventDefault();
    handleSignOut();
  };

  return (
    <StyledUserMenu>
      <StyledNavButton
        ref={toggleButtonRef}
        onClick={toggleOpen}
        aria-label="user menu"
      >
        <ProfileImage />
        <Chevron className="menu-chevron" />
      </StyledNavButton>
      {open && (
        <StyledDropdown
          ref={dropdownRef}
          onClick={toggleOpen}
          className={show ? 'open' : ''}
        >
          <div className="user-details">
            <span>{user.username}</span>
            <span className="user-email">{user.email}</span>
          </div>
          {tablet &&
            HEADER_NAV.map((item) => (
              <NavLink key={item.title} to={item.path} exact={true}>
                {item.title}
              </NavLink>
            ))}
          {HEADER_AUTH.map((item) => {
            if (item.permission?.includes('partner') && !user.is_partner)
              return null;

            return item.children ? (
              <div className="nav-group" key={item.title}>
                <span>{item.title}</span>
                {item.children.map((child) => (
                  <NavLink key={child.title} to={child.path}>
                    {child.title}
                  </NavLink>
                ))}
              </div>
            ) : (
              <NavLink key={item.title} to={item.path}>
                {item.title}
              </NavLink>
            );
          })}
          <NavLink to="/faq">FAQ</NavLink>
          <NavLink to="/signout" className="sign-out" onClick={handleClick}>
            Sign Out
          </NavLink>
        </StyledDropdown>
      )}
    </StyledUserMenu>
  );
};

export default MenuLoggedIn;
