import InfoTooltip from 'common/InfoTooltip/InfoTooltip';
import React from 'react';
import { StyledPlanPrice } from './PlanPrice.styled';
import { SUBSCRIPTION_TOOLTIPS } from 'constants/subscriptions';

const PlanPrice = ({ type, month, annual = true }) => (
  <StyledPlanPrice>
    <div className="plan-pricing-content">
      {month === 0 ? (
        <>
          <div className="price">
            Free<sub></sub>
          </div>
          <p>For the testing period</p>
        </>
      ) : (
        <>
          <div className="price">
            <sup>$</sup>
            {month}
            <sub>/mo *</sub>
            <InfoTooltip
              tooltip={SUBSCRIPTION_TOOLTIPS[type]}
              style={{
                verticalAlign: 'top',
                '--info-size': '1.3rem',
                marginInlineStart: '0.6rem',
                opacity: 0.6,
              }}
            />
          </div>
          <p>{annual ? 'Billed annually' : 'Starting at'}</p>
        </>
      )}
    </div>
  </StyledPlanPrice>
);

export default PlanPrice;
