export const size = {
  mobile: 500,
  tablet: 768,
  laptop: 1440,
  desktop: 2560,
};

export const device = {
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
};
