import * as searchApi from 'api/searchApi';

const types = {
  SEARCH_BAR_LOADING: 'SEARCH_BAR_LOADING',
  SEARCH_BAR_SIMILAR_SUCCESS: 'SEARCH_BAR_SIMILAR_SUCCESS',
  SEARCH_BAR_KEYWORD_SUCCESS: 'SEARCH_BAR_KEYWORD_SUCCESS',
  SEARCH_BAR_ERROR: 'SEARCH_BAR_ERROR',
  CLEAR_SEARCH_BAR: 'CLEAR_SEARCH_BAR',
};

export const searchKeywords = () => (dispatch) => {
  dispatch({ type: types.SEARCH_BAR_LOADING });
  // try {
  //   const results = await searchApi.convertKeywords(payload);
  //   return dispatch({
  //     type: types.SEARCH_BAR_KEYWORD_SUCCESS,
  //     payload: results,
  //   });
  // } catch (message) {
  //   dispatch({ type: types.SEARCH_BAR_ERROR, error: message });
  //   return message;
  // }
};

export const searchSpotify = (payload) => async (dispatch) => {
  dispatch({ type: types.SEARCH_BAR_LOADING });

  try {
    const results = await searchApi.searchSpotify(payload);
    return dispatch({
      type: types.SEARCH_BAR_SIMILAR_SUCCESS,
      payload: results,
    });
  } catch (message) {
    dispatch({ type: types.SEARCH_BAR_ERROR, error: message });
    return message;
  }
};

export const clearSearchBar = () => (dispatch) => {
  dispatch({ type: types.CLEAR_SEARCH_BAR });
};
