import React, { forwardRef } from 'react';
import SpotifySearchResults from '../Results/SpotifySearchResults';
import useSimilarityInput from './useSimilarityInput';
import { ReactComponent as Clear } from 'images/icons/clear.svg';
import { ReactComponent as Search } from 'images/icons/search.svg';
import { StyledSimilarityInput } from './SimilarityInput.styled';

const SimilarityInput = forwardRef((_, inputRef) => {
  const similarityProps = useSimilarityInput();
  const { results, loading, term, debouncedValue } = similarityProps;
  const { handleChange, handleSubmit, handleClose } = similarityProps;

  return (
    <StyledSimilarityInput>
      <Search className="search-icon" />
      <input
        ref={inputRef}
        value={term}
        onChange={handleChange}
        placeholder="Search for a song..."
      />
      {term && <Clear className="input-icon" onClick={handleClose} />}
      {(results || loading) && (
        <SpotifySearchResults
          results={results}
          term={debouncedValue}
          loading={loading}
          handleClose={handleClose}
          handleClick={handleSubmit}
        />
      )}
    </StyledSimilarityInput>
  );
});

export default SimilarityInput;
