import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  StyledUserMenu,
  StyledDropdown,
  StyledNavButton,
} from 'common/Layout/Header/Auth/Menu/UserMenu.styled';
import { ReactComponent as Menu } from 'images/icons/menu.svg';
import { Button } from 'common/Button';
import { HEADER_NAV } from 'constants/navigation';

const MenuLoggedOut = ({ handleSignUp, handleSignIn }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const toggleButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (open) {
      return setShow(true);
    }
    return setShow(false);
  }, [open]);

  useEffect(() => {
    if (open && dropdownRef.current) {
      const closeExternal = (e) => {
        if (
          !dropdownRef.current.contains(e.target) &&
          !toggleButtonRef.current.contains(e.target)
        ) {
          return setOpen(false);
        }
      };
      document.addEventListener('mousedown', closeExternal);
      setOpen(true);
      return () => document.removeEventListener('mousedown', closeExternal);
    }
  }, [open]);

  const toggleOpen = () => setOpen((open) => !open);

  return (
    <StyledUserMenu>
      <StyledNavButton
        ref={toggleButtonRef}
        onClick={toggleOpen}
        aria-label="user menu"
      >
        <Menu />
      </StyledNavButton>
      {open && (
        <StyledDropdown
          ref={dropdownRef}
          onClick={toggleOpen}
          className={show ? 'open' : ''}
        >
          <div className="user-details">
            <Button outline onClick={handleSignUp}>
              Sign Up
            </Button>
          </div>
          {HEADER_NAV.map((item) => (
            <NavLink key={item.title} to={item.path} exact={true}>
              {item.title}
            </NavLink>
          ))}
          <NavLink to="/pricing">Pricing</NavLink>
          <NavLink to="/faq">FAQ</NavLink>
          <NavLink to="/signin" onClick={handleSignIn}>
            Sign In
          </NavLink>
        </StyledDropdown>
      )}
    </StyledUserMenu>
  );
};
export default MenuLoggedOut;
