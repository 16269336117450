import React from 'react';
import Form, { CreditCardField, InputField, SubmitButton } from 'common/Form';
import useCheckoutForm from './useCheckoutForm';
import { Button } from 'common/Button';
import SubscriptionDetails from '../Details/SubscriptionDetails';

const CheckoutForm = ({ account, handleNext }) => {
  const {
    formik,
    sub,
    loading,
    error,
    coupon,
    free,
    disabled,
    applyDisabled,
    handleCode,
    handleCCChanges,
  } = useCheckoutForm(handleNext);

  return (
    <Form handleSubmit={formik.handleSubmit} account={account}>
      {!sub?.type && (
        <>
          <SubscriptionDetails
            account={account}
            coupon={coupon}
            formik={formik}
          />
          <div className="field-button-wrapper">
            <InputField
              label="Have a Coupon Code?"
              name="code"
              autoComplete="off"
              formik={formik}
              inlineButton={
                <Button
                  type="button"
                  onClick={() => handleCode(formik.values.code)}
                  disabled={applyDisabled}
                  style={{ background: '#ffffff38' }}
                >
                  Apply
                </Button>
              }
            />
          </div>
        </>
      )}
      {!free && (
        <CreditCardField
          label="Credit Card"
          error={error}
          handleChange={handleCCChanges}
        />
      )}
      <SubmitButton
        outline={account}
        loading={loading}
        disabled={(disabled && !free) || !applyDisabled}
        loadingLabel="Processing..."
      >
        {sub?.type ? 'Update' : 'Subscribe'}
      </SubmitButton>
    </Form>
  );
};

export default CheckoutForm;
