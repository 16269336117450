import React from 'react';
import { ReactComponent as Person } from 'images/icons/person-fill.svg';
import { StyledProfileImage } from './ProfileImage.styled';

const ProfileImage = ({ imgSrc, small, Icon }) => {
  return (
    <StyledProfileImage image={imgSrc} small={small} full={Icon}>
      {Icon ? <Icon /> : !imgSrc ? <Person /> : null}
    </StyledProfileImage>
  );
};

export default ProfileImage;
