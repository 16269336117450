import React from 'react';
import { Button } from 'common/Button';

const SubmitButton = ({
  children,
  loadingLabel,
  loading,
  disabled,
  name,
  width,
  outline,
}) => (
  <Button
    primary={!outline}
    outline={outline}
    type="submit"
    disabled={disabled || loading}
    full={!width}
    width={width}
    aria-label={name}
  >
    {loading ? loadingLabel || 'Loading ...' : children}
  </Button>
);

export default SubmitButton;
