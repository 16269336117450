import styled from 'styled-components';
import { device } from 'styles/device';

export const StyledSearchTags = styled.div`
  position: absolute;
  top: calc(var(--header-height) + var(--search-height));
  font-size: 0.875rem;
  max-width: 90%;
  background: #111;
  width: 520px;
  left: 136px;
  padding: 8px;
  display: none;

  .tags-header {
    & > div {
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      padding: 8px;
      position: relative;

      &:hover {
        color: ${({ theme }) => theme.textHighlight};
      }
      &.active {
        color: ${({ theme }) => theme.textHighlight};

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          background: linear-gradient(
            59deg,
            hsla(261, 56%, 43%, 1) 20%,
            hsla(228, 58%, 54%, 1)
          );
          left: 0;
          bottom: -2px;
        }
      }

      & + div {
        margin-left: 16px;
      }
    }
  }
  .tags {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px 16px;
    cursor: pointer;
    padding: 24px;

    & > div {
      &:hover {
        color: ${({ theme }) => theme.textHighlight};
      }
    }
  }
  &:hover {
    display: block;
  }
`;

export const StyledSearchResultGroup = styled.div`
  & + & {
    margin-top: 36px;
  }

  .view-all {
    padding: 8px 16px;
    font-size: 0.85rem;
    font-weight: 600;
    opacity: 0.4;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const StyledSearchLoadingGroup = styled(StyledSearchResultGroup)`
  h5 {
    display: block;
    width: 120px;
    color: #242424;
  }

  .view-all {
    opacity: 1;

    span {
      display: block;
      width: 120px;
      color: #242424;
    }
  }
`;

export const StyledSearchResultItem = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 16px;
  cursor: pointer;
  block-size: 3.8rem;
  margin-block-end: 0.3rem;
  transition: background-color 0.1s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .thumbnail {
    inline-size: 36px;
    block-size: 36px;
    background-image: ${({ image }) => `url(${image})`};
    border-radius: ${({ round }) => (round ? '100px' : 0)};
    background-size: cover;
    ${({ cinema }) => cinema && `height: 20px; margin: 8px 0; `}
    transition: background-color 0.1s, opacity 0.1s;

    svg {
      display: block;
      padding: 6px;
      box-sizing: initial;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .details {
    margin-left: 8px;
    flex: 1;
    height: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      vertical-align: middle;
    }

    .text {
      font-size: 10px;
      opacity: 0.6;
    }
  }
`;

export const StyledSearchLoadingItem = styled(StyledSearchResultItem)`
  .content-loading {
    color: #242424;
  }
  .thumbnail {
    background: #ff00ff;

    svg {
      display: none;
    }
  }
  .details {
    .title {
      height: 18px;
      width: 200px;
    }
    .text {
      height: 11px;
      width: 70px;
      opacity: 1;
      margin-top: 2px;
    }
  }
  &:hover {
    background: none;
    cursor: default;
  }
`;

export const StyledSearchResultPill = styled(StyledSearchResultItem)`
  background: rgba(255, 255, 255, 0.03);

  .details {
    .active {
      position: absolute;
      left: 30px;
      width: 16px;
    }
    .clear {
      position: absolute;
      right: 30px;
      width: 16px;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  &:hover .details .clear {
    opacity: 1;
  }
`;

export const StyledSearchLoadingPill = styled(StyledSearchResultPill)`
  background: none;

  .content-loading {
    color: #242424;
  }

  .details .title {
    width: 100px;
  }
  &:hover {
    background: none;
    cursor: default;
  }
`;

export const StyledSearchResultLoading = styled.div`
  .loading-header {
    height: 14px;
    width: 80px;
    background: rgba(255, 255, 255, 0.03);
    margin: 18px 16px;
  }
  .loading-item {
    background: rgba(255, 255, 255, 0.03);
    height: 36px;
    margin: 16px;
  }
`;

export const StyledSearchNoResults = styled.div`
  padding: 32px;
  text-align: center;
`;

export const StyledSearchResults = styled.div`
  position: absolute;
  top: var(--search-height);
  left: 4rem;
  color: ${({ theme }) => theme.textHighlight};
  font-size: 0.875rem;
  width: 430px;
  max-width: 90%;

  .underlay-arriba {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: calc(var(--header-height) + var(--search-height)) 0 0;
    z-index: 5;
  }
  .content-wrapper {
    background: #191919;
    overflow: auto;
    max-height: calc(
      100vh - (calc(var(--header-height) + var(--search-height)))
    );
    position: relative;
    z-index: 9;

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
      }
    }
    .content {
      padding: 16px;

      h5 {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 600;
        margin: 8px 16px;
      }
    }
  }
  @media ${device.tablet} {
    left: 5%;
    width: 90%;
  }
`;

export const StyledSearchBar = styled.div`
  inline-size: 100%;
  block-size: var(--search-height);
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 200ms;
  position: fixed;
  top: var(--header-height);
  z-index: 9;
  transition: padding 0.15s;

  .search-contents {
    background: #111;
    position: relative;
    block-size: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    z-index: 999;
    padding: 6px 16px;

    .search-icon {
      inline-size: 20px;
      block-size: 20px;
      animation: ${({ loading }) =>
        loading ? 'spinner 1.2s infinite' : 'none'};
      transition: rotate 200ms, transform 160ms, opacity 160ms;

      &.hide {
        opacity: 0;
        transform: translateX(-50px);
      }
    }

    .input-icon {
      inline-size: 20px;
      block-size: 20px;
      padding: 8px;
      cursor: pointer;
      box-sizing: initial;
      margin-right: 8px;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: 0;
      display: block;
      flex: 1;
      height: 100%;
      background: none;
      color: #fff;
      font-size: inherit;
      outline: none;
      transition: opacity 160ms 80ms;

      &.hide {
        opacity: 0;
        cursor: default;
      }
    }
  }

  &:focus-within,
  &.active {
    opacity: 1;

    input::placeholder {
      color: ${({ theme }) => theme.textPlaceholder};
    }
    + ${StyledSearchTags} {
      display: block;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1080deg);
    }
  }
`;
