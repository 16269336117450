import React, { forwardRef } from 'react';
import {
  StyledPill,
  StyledPills,
  StyledKeywordInput,
} from './KeywordInput.styled';
import useKeywordInput from './useKeywordInput';
import { Button } from 'common/Button';
import { ReactComponent as Clear } from 'images/icons/clear.svg';
import { ReactComponent as Search } from 'images/icons/search.svg';

const KeywordInput = forwardRef((_, inputRef) => {
  const {
    pills,
    value,
    handleChange,
    handleAdd,
    handleSubmit,
    handleRemove,
    handleClear,
  } = useKeywordInput();

  return (
    <StyledKeywordInput onSubmit={handleAdd}>
      <Search className="search-icon" />
      <StyledPills content={pills.length}>
        {pills.map((pill) => (
          <StyledPill key={pill.id} onClick={() => handleRemove(pill.id)}>
            {pill.value}
          </StyledPill>
        ))}
      </StyledPills>
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        placeholder={pills.length ? 'Add another tag...' : 'Search anything...'}
      />
      {value && <Clear className="input-icon" onClick={handleClear} />}
      {value || value.length > 3 ? (
        <Button type="button" onClick={handleAdd}>
          Add
        </Button>
      ) : pills.length ? (
        <Button primary type="button" onClick={handleSubmit}>
          Search
        </Button>
      ) : null}
    </StyledKeywordInput>
  );
});

export default KeywordInput;
