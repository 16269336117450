import { getLsToken, parseJwt } from 'utilities';
import { userSignOut } from 'redux/actions/userActions';
import { getLsUser } from 'utilities/localStorage';

export const session = (store) => (next) => (action) => {
  const { refresh } = getLsToken();
  const { user } = getLsUser();

  if (user && !refresh) {
    return store.dispatch(userSignOut({ force: true }));
  }
  if (refresh) {
    const { exp } = parseJwt(refresh);

    if (exp * 1000 - Date.now() < 0) {
      return store.dispatch(userSignOut({ force: true }));
    }
  }
  return next(action);
};
