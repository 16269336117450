import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'images/persona-logo.svg';
import HeaderNav from './Nav/HeaderNav';
import HeaderAuth from './Auth/HeaderAuth';
import { StyledHeader, StyledHeaderWrapper } from './Header.styled';
import { useViewport } from 'hooks';

const Header = () => {
  const { tablet } = useViewport();

  return (
    <StyledHeaderWrapper>
      <StyledHeader>
        <Link to="/" className="site-logo" aria-label="home">
          <Logo alt="logo" />
        </Link>
        {!tablet && <HeaderNav />}
        <HeaderAuth tablet={tablet} />
      </StyledHeader>
    </StyledHeaderWrapper>
  );
};

export default Header;
