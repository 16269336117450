import styled from 'styled-components';

export const StyledPaymentComponent = styled.div`
  .subscription-prices {
    margin-block-end: 3rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    .subscription-type {
      opacity: 0.5;
      transition: opacity 0.2s;

      &.active {
        opacity: 1;
      }
    }
  }
`;
