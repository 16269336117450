import * as subscriptionApi from 'api/subscriptionApi';
import toastr from 'utilities/toastr';
import moment from 'moment';

const types = {
  SUBSCRIPTION_LOADING: 'SUBSCRIPTION_LOADING',
  SUBSCRIPTION_ERROR: 'SUBSCRIPTION_ERROR',
  SUBSCRIPTION_CREATE_SUCCESS: 'SUBSCRIPTION_CREATE_SUCCESS',
  SUBSCRIPTION_UPDATE_SUCCESS: 'SUBSCRIPTION_UPDATE_SUCCESS',
  SUBSCRIPTION_TOGGLE_LOADING: 'SUBSCRIPTION_TOGGLE_LOADING',
  SUBSCRIPTION_TOGGLE_SUCCESS: 'SUBSCRIPTION_TOGGLE_SUCCESS',
  SUBSCRIPTION_TOGGLE_ERROR: 'SUBSCRIPTION_TOGGLE_ERROR',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  CLEAR_SUBSCRIPTION_ERROR: 'CLEAR_SUBSCRIPTION_ERROR',
};

export const subscriptionLoading = () => (dispatch) => {
  dispatch({ type: types.SUBSCRIPTION_LOADING });
};

export const subscriptionError = (message) => (dispatch) => {
  dispatch({
    type: types.SUBSCRIPTION_ERROR,
    errors: { payment: message },
  });
};

export const subscriptionCreate = (payload) => (dispatch) => {
  dispatch(subscriptionLoading());

  return subscriptionApi
    .createSubscription(payload)
    .then(({ user }) => {
      toastr.success('Subscription created!');
      dispatch({ type: types.SUBSCRIPTION_UPDATE_SUCCESS });
      dispatch({ type: types.USER_UPDATE_SUCCESS, payload: user });
    })
    .catch((message) => {
      dispatch(subscriptionError(message));
      return message;
    });
};

export const confirmSubscription = (user, paymentMethodId) => (dispatch) => {
  if (!user.subscription?.paid_expiration) {
    user.subscription.paid_expiration = moment().add(1, 'y');
  }
  return subscriptionApi.updatePaymentMethod(paymentMethodId).then(() => {
    toastr.success('Subscription created!');
    dispatch({ type: types.SUBSCRIPTION_UPDATE_SUCCESS });
    dispatch({ type: types.USER_UPDATE_SUCCESS, payload: user });
  });
};

export const updatePaymentMethod = (id) => (dispatch, getState) => {
  const { user } = getState().auth;
  if (!user.subscription?.paid_expiration) {
    user.subscription.paid_expiration = moment().add(1, 'y');
  }
  return subscriptionApi.updatePaymentMethod(id).then(() => {
    toastr.success('Payment info updated!');
    dispatch({ type: types.SUBSCRIPTION_UPDATE_SUCCESS });
    dispatch({ type: types.USER_UPDATE_SUCCESS, payload: user });
  });
};

export const toggleRenewal = (payload) => (dispatch) => {
  dispatch({ type: types.SUBSCRIPTION_TOGGLE_LOADING });
  return subscriptionApi
    .toggleRenewal(payload)
    .then((user) => {
      if (!user.subscription?.paid_expiration) {
        user.subscription.paid_expiration = moment().add(1, 'y');
      }
      dispatch({ type: types.USER_UPDATE_SUCCESS, payload: user });
      dispatch({ type: types.SUBSCRIPTION_TOGGLE_SUCCESS });
    })
    .catch((message) => {
      dispatch({
        type: types.SUBSCRIPTION_TOGGLE_ERROR,
        errors: { toggle: message },
      });
      return message;
    });
};

export const clearSubscriptionError = () => (dispatch) => {
  dispatch({ type: types.CLEAR_SUBSCRIPTION_ERROR });
};
