import React from 'react';
import { FormGroup, Label, Input, Error } from '../Form.styled';
import InfoTooltip from 'common/InfoTooltip/InfoTooltip';
import { formTooltips } from 'constants/tooltips';
import PasswordRules from 'common/PasswordRules/PasswordRules';
import { useState } from 'react';

const InputField = ({
  label,
  name,
  type = 'text',
  placeholder,
  formik,
  autoComplete,
  tooltip,
  inlineButton,
  disabled,
  className,
}) => {
  const [passwordRulesOpen, setPasswordRulesOpen] = useState(false);
  const isPassword = name === 'password';

  const handlePasswordRules = () => {
    isPassword && setPasswordRulesOpen(true);
  };

  return (
    <FormGroup inlineButton={!!inlineButton}>
      <Label>
        <span>{label}</span>
        {tooltip && (
          <InfoTooltip tooltip={formTooltips[tooltip]} position="top" />
        )}
      </Label>
      <span className="input-wrapper">
        <Input
          className={className}
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={handlePasswordRules}
          autoComplete={autoComplete}
          disabled={disabled}
        />
        {inlineButton}
      </span>
      {isPassword && (
        <PasswordRules value={formik.values[name]} open={passwordRulesOpen} />
      )}
      {formik.errors[name] && formik.touched[name] && (
        <Error>{formik.errors[name]}</Error>
      )}
    </FormGroup>
  );
};

export default InputField;
