import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useKeywordInput = () => {
  const [pills, setPills] = useState([]);
  const [value, setValue] = useState('');
  const TERM_LIMIT = 30;
  const history = useHistory();

  const addPill = () => {
    if (value.length < 3) return;

    const id = pills[pills.length - 1]?.id || 0;
    setPills((p) => [...p, { id: id + 1, value: value.trim() }]);
    setValue('');
  };

  const handleRemove = (id) => {
    setPills((p) => p.filter((q) => q.id !== id));
  };

  const handleChange = ({ target: { value } }) => {
    if (value.length > TERM_LIMIT || value === ' ') return;

    if (value.length > 1 && value.includes(',')) {
      return addPill();
    }
    setValue(value);
  };

  const handleSubmit = () => {
    const keywords = pills.map((p) => p.value);
    const params = new URLSearchParams();

    setValue('');
    setPills([]);

    params.set('keywords', keywords);
    history.push(`/search?${params.toString()}`);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    if (value.length === 0) {
      return handleSubmit();
    }

    addPill();
  };

  const handleClear = () => {
    setValue('');
  };

  return {
    pills,
    value,
    handleAdd,
    handleChange,
    handleSubmit,
    handleRemove,
    handleClear,
  };
};

export default useKeywordInput;
