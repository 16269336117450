import styled from 'styled-components';

export const StyledIconButton = styled.button`
  position: relative;
  vertical-align: top;

  & > span {
    padding: 3px;
    display: block;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
