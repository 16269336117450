import { useState, useEffect } from 'react';
import { size } from 'styles/device';

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // TODO: throttle this
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  return { width, tablet: width <= size.tablet, mobile: width <= size.mobile };
};

export { useViewport };

// This actually re-rendered more...
// TODO: revert context based resize listener
// import { useContext } from 'react';
// import { ViewportContext } from 'context/ViewportContext';

// const MOBILE = 900;

// const useViewport = () => {
//   const { width } = useContext(ViewportContext);
//   return { width, mobile: width <= MOBILE };
// };

// export { useViewport };
