import styled from 'styled-components';
import { StyledModalContent } from 'common/Modal/Modal.styled';

export const StyledSubscribeModal = styled(StyledModalContent)`
  .modal-header {
    h2 {
      margin-block-end: 3rem;
    }
  }
`;
