import * as userApi from 'api/userApi.js';
import { clearHomeTracks } from './homeActions';
import { clearMusicTracks } from './musicActions';
import { getFavorites } from './favoritesActions';
import { getProjects } from './projectsActions';
import { getPurchases } from './purchasesActions';
import { openModal } from './modalActions';
import { setGateAccess } from './gateActions';
import { mixpanel, clearLsStore } from 'utilities';
import toastr from 'utilities/toastr';
import { getTags } from './tagsActions';

const types = {
  USER_SIGN_IN_LOADING: 'USER_SIGN_IN_LOADING',
  USER_SIGN_IN_SUCCESS: 'USER_SIGN_IN_SUCCESS',
  USER_SIGN_IN_ERROR: 'USER_SIGN_IN_ERROR',
  USER_SIGN_UP_LOADING: 'USER_SIGN_UP_LOADING',
  USER_SIGN_UP_SUCCESS: 'USER_SIGN_UP_SUCCESS',
  USER_SIGN_UP_ERROR: 'USER_SIGN_UP_ERROR',
  USER_VERIFY_LOADING: 'USER_VERIFY_LOADING',
  USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
  USER_VERIFY_ERROR: 'USER_VERIFY_ERROR',
  USER_FORGOT_LOADING: 'USER_FORGOT_LOADING',
  USER_FORGOT_SUCCESS: 'USER_FORGOT_SUCCESS',
  USER_FORGOT_ERROR: 'USER_FORGOT_ERROR',
  USER_UPDATE_LOADING: 'USER_UPDATE_LOADING',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
  USER_RESET_LOADING: 'USER_RESET_LOADING',
  USER_RESET_SUCCESS: 'USER_RESET_SUCCESS',
  USER_RESET_ERROR: 'USER_RESET_ERROR',
  USER_ACTIVATE_LOADING: 'USER_ACTIVATE_LOADING',
  USER_ACTIVATE_SUCCESS: 'USER_ACTIVATE_SUCCESS',
  USER_ACTIVATE_ERROR: 'USER_ACTIVATE_ERROR',
  USER_SIGN_OUT_SUCCESS: 'USER_SIGN_OUT_SUCCESS',
  CLEAR_STORE: 'CLEAR_STORE',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

export const clearSiteContent = () => (dispatch, getState) => {
  const { user } = getState().auth;

  if (user) {
    dispatch(getProjects());
    dispatch(getPurchases());
    dispatch(getFavorites());
  }
  dispatch(getTags());
  dispatch(clearHomeTracks());
  dispatch(clearMusicTracks());
};

export const userSignIn = (payload) => (dispatch) => {
  dispatch({ type: types.USER_SIGN_IN_LOADING });
  return userApi
    .userSignIn(payload)
    .then((user) => {
      dispatch({ type: types.USER_SIGN_IN_SUCCESS, payload: user });
      dispatch(setGateAccess());
      dispatch(clearSiteContent());
      toastr.success('Welcome back!');
      mixpanel.identify(user.email);
      mixpanel.track('Sign In');
      // If the user already existed in the system
      mixpanel.alias(user.email);
      mixpanel.people.set_once({
        $email: user.email,
        $name: user.username,
        'Subscription Type': user.subscription?.type,
      });
    })
    .catch((message) => {
      dispatch({ type: types.USER_SIGN_IN_ERROR, error: message });
      return message;
    });
};

export const userSignUp = (payload) => (dispatch) => {
  dispatch({ type: types.USER_SIGN_UP_LOADING });
  return userApi
    .userSignUp(payload)
    .then((user) => {
      dispatch({ type: types.USER_SIGN_UP_SUCCESS, payload: user });
      dispatch(setGateAccess());
      dispatch(clearSiteContent());
      toastr.success('Welcome aboard!');
      mixpanel.alias(user.email);
      mixpanel.track('Sign Up', { Name: user.username });
      mixpanel.people.set({
        $email: user.email,
        $name: user.username,
        'Subscription Type': user.subscription?.type,
      });
      return user;
    })
    .catch((messages) => {
      dispatch({ type: types.USER_SIGN_UP_ERROR, error: messages });
      throw messages;
    });
};

export const userVerify = (payload) => (dispatch) => {
  dispatch({ type: types.USER_VERIFY_LOADING });
  return userApi
    .userVerify(payload)
    .then((user) => {
      toastr.success('Email verified, welcome aboard!');
      dispatch(clearSiteContent());
      dispatch({ type: types.USER_VERIFY_SUCCESS, payload: user });
      dispatch(setGateAccess());
    })
    .catch((error) => {
      toastr.error(error.message);
      dispatch({ type: types.USER_VERIFY_ERROR });
      return error;
    });
};

export const userUpdate = (payload) => (dispatch) => {
  dispatch({ type: types.USER_UPDATE_LOADING });
  return userApi
    .userUpdate(payload)
    .then((user) => {
      toastr.success('Your details have been updated!');
      dispatch({ type: types.USER_UPDATE_SUCCESS, payload: user });
    })
    .catch((messages) => {
      dispatch({ type: types.USER_UPDATE_ERROR, error: messages });
      return messages;
    });
};

export const userForgotPass = (payload) => (dispatch) => {
  dispatch({ type: types.USER_FORGOT_LOADING });
  return userApi
    .userForgotPass(payload)
    .then((email) => {
      toastr.success(`An email has been sent to ${email}`);
      dispatch({ type: types.USER_FORGOT_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: types.USER_FORGOT_ERROR, error });
      return error;
    });
};

export const userCheckResetToken = (payload) => (dispatch) => {
  dispatch({ type: types.USER_RESET_LOADING });
  return userApi
    .userCheckResetToken(payload)
    .then(() => {
      dispatch({ type: types.USER_RESET_SUCCESS });
      return;
    })
    .catch((error) => {
      toastr.error(error);
      dispatch({ type: types.USER_RESET_ERROR, error });
      return error;
    });
};

export const userResetPass = (payload) => (dispatch) => {
  dispatch({ type: types.USER_RESET_LOADING });
  return userApi
    .userResetPass(payload)
    .then(() => {
      toastr.success(`Your password has been successfully reset`);
      dispatch({ type: types.USER_RESET_SUCCESS });
      return;
    })
    .catch((error) => {
      toastr.error(error);
      dispatch({ type: types.USER_RESET_ERROR, error });
      return error;
    });
};

export const userActivatePass = (payload) => (dispatch) => {
  dispatch({ type: types.USER_ACTIVATE_LOADING });
  return userApi
    .userActivatePass(payload)
    .then(() => {
      toastr.success(`Your account has been activated!`);
      dispatch({ type: types.USER_ACTIVATE_SUCCESS });
      return;
    })
    .catch((error) => {
      toastr.error(error);
      dispatch({ type: types.USER_ACTIVATE_ERROR, error });
      return error;
    });
};

export const getCurrentUser = () => (dispatch) => {
  return userApi.getCurrentUser().then((user) => {
    dispatch({ type: types.USER_SIGN_IN_SUCCESS, payload: user });
  });
};

export const userSignOut = (options) => (dispatch) => {
  clearLsStore();
  dispatch({ type: types.CLEAR_STORE });

  if (options?.force) {
    toastr.error(`Your session expired, you've been signed out`);
    dispatch(openModal('signin'));
  }
  if (options?.signin) {
    dispatch(openModal('signin'));
  }

  mixpanel.track('Sign Out');
  mixpanel.reset();
};

export const clearError = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ERROR });
};
