const initialState = {
  track: null,
  queue: [],
  index: 0,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUEUE_AND_PLAY_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'SET_QUEUE_AND_PLAY_SUCCESS':
      return {
        track: action.payload.list[action.payload.index],
        queue: action.payload.list,
        index: action.payload.index,
        loading: false,
        error: null,
      };

    case 'SET_NEXT_TRACK_SUCCESS':
    case 'SET_PREVIOUS_TRACK_SUCCESS':
      return {
        ...state,
        track: state.queue[action.payload],
        index: action.payload,
        loading: false,
        error: null,
      };

    case 'SET_QUEUE_AND_PLAY_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLOSE_PLAYER':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
