import * as projectsApi from 'api/projectsApi';
import { openModal } from './modalActions';
import toastr from 'utilities/toastr';

const types = {
  GET_PROJECTS_LOADING: 'GET_PROJECTS_LOADING',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_ERROR: 'GET_PROJECTS_ERROR',
  CREATE_PROJECT_LOADING: 'CREATE_PROJECT_LOADING',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_ERROR: 'CREATE_PROJECT_ERROR',
  UPDATE_PROJECT_LOADING: 'UPDATE_PROJECT_LOADING',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_ERROR: 'UPDATE_PROJECT_ERROR',
  DELETE_PROJECT_LOADING: 'DELETE_PROJECT_LOADING',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',
};

export const getProjects = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user) return;
    dispatch({ type: types.GET_PROJECTS_LOADING });
    return projectsApi
      .getProjects()
      .then((projects) => {
        dispatch({
          type: types.GET_PROJECTS_SUCCESS,
          payload: projects,
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_PROJECTS_ERROR, error: message });
        return message;
      });
  };
};

export const createProjectAddTrack = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_PROJECT_LOADING });
    return projectsApi
      .createProjectAddTrack(payload)
      .then((project) => {
        const message = `Project '${project.name}' created, '${project.tracks[0].title}' added`;
        toastr.success(message);
        dispatch({
          type: types.CREATE_PROJECT_SUCCESS,
          payload: project,
        });
      })
      .catch((message) => {
        dispatch({ type: types.CREATE_PROJECT_ERROR, error: message });
        return message;
      });
  };
};

export const addTrackToProject = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PROJECT_LOADING });
    return projectsApi
      .addTrackToProject(payload)
      .then((project) => {
        const track = project.tracks.find((t) => t.id === payload.trackId);
        const message = `'${track.title}' added to '${project.name}'`;
        toastr.success(message);
        dispatch({
          type: types.UPDATE_PROJECT_SUCCESS,
          payload: project,
        });
      })
      .catch((message) => {
        dispatch({ type: types.UPDATE_PROJECT_ERROR, error: message });
        return message;
      });
  };
};

export const removeTrackFromProject = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PROJECT_LOADING });
    return projectsApi
      .removeTrackFromProject(payload)
      .then((project) => {
        const message = `'${payload.trackTitle}' removed from '${project.name}'`;
        toastr.success(message);
        dispatch({
          type: types.UPDATE_PROJECT_SUCCESS,
          payload: project,
        });
      })
      .catch((message) => {
        dispatch({
          type: types.UPDATE_PROJECT_ERROR,
          error: message,
        });
        return message;
      });
  };
};

export const deleteProject = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_PROJECT_LOADING });
    return projectsApi
      .deleteProject(payload)
      .then((project) => {
        dispatch({
          type: types.DELETE_PROJECT_SUCCESS,
          payload: project,
        });
      })
      .catch((message) => {
        dispatch({ type: types.DELETE_PROJECT_ERROR, error: message });
        return message;
      });
  };
};

export const setProjectTrack = (payload) => (dispatch, getState) => {
  if (!getState().auth.user) {
    return dispatch(openModal('signin'));
  }
  dispatch(openModal('project', { track: payload }));
};
