import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import { StyledIconButton } from './IconButton.styled';

const IconButton = ({ tooltip, children, handleClick, ...props }) => (
  <StyledIconButton type="button" onClick={handleClick} {...props}>
    {tooltip ? (
      <StatefulToolTip
        parent={children}
        position="top"
        tooltipTimeout={0}
        arrow="center"
      >
        {tooltip}
      </StatefulToolTip>
    ) : (
      children
    )}
  </StyledIconButton>
);

export default IconButton;
