import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userForgotPass,
  userSignIn,
  clearError,
} from 'redux/actions/userActions';
import { openModal } from 'redux/actions/modalActions';
import SignInForm from './SignInForm';
import ForgotForm from './ForgotForm';
import { StyledModalContent } from 'common/Modal/Modal.styled';
import { useHistory } from 'react-router';

const SignInPage = ({ redirect, handleClose }) => {
  const { loading, error } = useSelector((state) => state.auth);
  const [forgot, setForgot] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => dispatch(clearError());
  }, [dispatch]);

  const handleForgot = () => setForgot(!forgot);
  const handleSignup = () => dispatch(openModal('signup', { redirect }));

  const handleForgotPass = (values) => {
    dispatch(userForgotPass(values)).then((err) => {
      if (!err) handleClose();
    });
  };

  const handleSubmit = (values) => {
    const data = { ...values, email: values.email.toLowerCase() };
    dispatch(userSignIn(data)).then((err) => {
      if (!err) {
        handleClose();
        if (redirect) {
          history.push(redirect);
        }
      }
    });
  };

  return (
    <StyledModalContent>
      {forgot ? (
        <>
          <div className="modal-header">
            <h3>Forgot Password</h3>
            <p>Enter your email address to reset your password</p>
          </div>
          <ForgotForm
            loading={loading}
            error={error}
            handleForgot={handleForgot}
            handleSubmit={handleForgotPass}
          />
        </>
      ) : (
        <>
          <div className="modal-header">
            <h3>Sign In</h3>
          </div>
          <SignInForm
            loading={loading}
            error={error}
            handleForgot={handleForgot}
            handleSignup={handleSignup}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </StyledModalContent>
  );
};

export default SignInPage;
