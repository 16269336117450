import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

const SvgWaveOverlay = ({ tooltip, handleSeek, handlePos }) => {
  const overlay = (
    <span
      className="overlay"
      onClick={handleSeek}
      onMouseMove={handlePos}
    ></span>
  );
  return (
    <StatefulToolTip
      className="overlay"
      parent={overlay}
      position="top"
      tooltipTimeout={0}
    >
      {tooltip}
    </StatefulToolTip>
  );
};

export default SvgWaveOverlay;
