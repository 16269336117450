import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import { ReactComponent as Info } from 'images/icons/info.svg';
import { StyledInfo, StyledTooltip } from './InfoTooltip.styled';

const InfoTooltip = ({ tooltip: Tooltip, style, position = 'right' }) =>
  Tooltip ? (
    <StyledInfo style={style}>
      <StatefulToolTip
        className="info"
        parent={<Info />}
        position={position}
        tooltipTimeout={500}
      >
        <StyledTooltip>
          <Tooltip />
        </StyledTooltip>
      </StatefulToolTip>
    </StyledInfo>
  ) : null;

export default InfoTooltip;
